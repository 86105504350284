<template>
  <el-dialog :title="!isEditing ? 'Добавить пользователя' : 'Редактирование пользователя'" :visible.sync="dialogFormVisible" width="450px" :close-on-click-modal="false">
    <el-form v-if="!isEditing" size="small" label-position="left"  label-width="100px" :model="form" ref="ruleForm1" :rules="rules" >
      <el-form-item label="Имя" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Фамилия" prop="lastName">
        <el-input v-model="form.lastName"></el-input>
      </el-form-item>
      <el-form-item label="Отчество" prop="patronymic">
        <el-input v-model="form.patronymic"></el-input>
      </el-form-item>
      <el-form-item label="ИИН" prop="iin">
        <el-input v-model="form.iin"></el-input>
      </el-form-item>
      <el-form-item label="Компания" prop="company">
        <el-select v-model="form.company" class="m-2" placeholder="Компания" size="large">
          <el-option
              v-for="item in companies"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Роль" prop="role">
        <el-select v-model="form.role" class="m-2" placeholder="Роль" size="large">
          <el-option
              v-for="item in roles"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Позиция" prop="position">
        <el-input v-model="form.position"></el-input>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="Логин" prop="username">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="Пароль" prop="password">
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
    </el-form>
    <el-form v-else size="small" label-position="left"  label-width="100px" :model="form" ref="ruleForm" :rules="rules" >
      <el-form-item label="Имя" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Фамилия" prop="lastName">
        <el-input v-model="form.lastName"></el-input>
      </el-form-item>
      <el-form-item label="Отчество" prop="patronymic">
        <el-input v-model="form.patronymic"></el-input>
      </el-form-item>
      <el-form-item label="Позиция" prop="position">
        <el-input v-model="form.position"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">Отмена</el-button>
    <el-button type="primary" @click="save">Сохранить</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    users: Array,
    companies: Array,
    editItem: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dialogFormVisible: false,
      isEditing: false,
      form: {
        username: '',
        name: '',
        lastName: '',
        patronymic: '',
        iin: '',
        position: '',
        password: '',
        company: '',
        role: ''
      },
      rules: {
        username: [
          { required: true, message: 'Введите логин', trigger: 'blur' }
        ],
        name: [{ required: true, message: 'Введите имя', trigger: 'blur' }],
        lastName: [{ required: true, message: 'Введите фимилию', trigger: 'blur' }],
        patronymic: [{ required: true, message: 'Введите отчество', trigger: 'blur' }],
        iin: [{ required: true, message: 'Введите ИИН', trigger: 'blur' }],
        position: [{ required: true, message: 'Введите позицию', trigger: 'blur' }],
        password: [{ required: true, message: 'Введите пароль', trigger: 'blur' }]
      },
      roles: ['ROLE_ALL', 'ROLE_CREATE_TICKETS', 'ROLE_SIGN_DOCS', 'ROLE_VIEW_DOCS', 'ROLE_VIEW_TICKET']
    }
  },
  methods: {
    save () {
      if (this.isEditing) {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            this.$emit('editUser', this.form)
            this.$refs['ruleForm'].resetFields()
          }
        })
      } else {
        this.$refs['ruleForm1'].validate((valid) => {
          if (valid) {
            this.$emit('addNewUser', this.form)
            this.$refs['ruleForm1'].resetFields()
          }
        })
      }
    }
  },
  watch: {
    isOpen (val) {
      if (!val) {
        this.$refs['ruleForm1'].resetFields()
      }
      this.dialogFormVisible = val
    },
    dialogFormVisible (val) {
      this.$emit('close', val)
    },
    editItem (val) {
      this.isEditing = !!val
      if (val) {
        console.log(this.editItem)
        this.form = {
          id: this.editItem.id,
          name: this.editItem.userProfile.name,
          lastName: this.editItem.userProfile.lastName,
          patronymic: this.editItem.userProfile.patronymic,
          position: this.editItem.userProfile.position
        }
      } else {
        this.$refs['ruleForm1'].resetFields()
      }
    }
  }
}
</script>

<style scoped>
.el-dialog__body {
  padding: 10px 20px !important;
}
</style>
