<template>
  <el-card>
    Главная страница
  </el-card>
</template>

<script>

export default {
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">

</style>
