import axios from 'axios'
import { MAIN_API } from './apiUrl'

const chainRequestsApi = {
  getServices () {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/alter/chain/all/services',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'GET'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  addNewService (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/alter/chain/add/service',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        data,
        method: 'POST'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  editService (id, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/alter/chain/update/service',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        params: { id },
        data,
        method: 'PUT'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getDocuments () {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/alter/chain/all/documents',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'GET'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  addNewDocument (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/alter/chain/add/document',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        data,
        method: 'POST'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  editDocument (id, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/alter/chain/update/document',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        params: { id },
        data,
        method: 'PUT'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default chainRequestsApi
