<template>
  <el-card v-loading="isLoading">
    <el-button @click="createExel">
      <i class="el-icon-download" style="font-weight: bold"/>
      Excel
    </el-button>
    <div class="text-center mb-4 mt-lg-n6 text--secondary font-weight-bold" style="text-align:center; width: 100%; font-size: 15px; margin: 30px 0; font-weight: bolder" v-html="reportSubtitle"></div>
    <el-tabs v-if="Object.keys(data).length > 1" v-model="tab" @tab-click="tabClick">
      <el-tab-pane
          v-for="(value, key) in data"
          :label="key"
          :name="key"
          :key="key">
        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <el-button @click="clearFilter()" size="mini">Сбросить все фильтра</el-button>
          <el-input
              v-model="search"
              @input="searchInput"
              style="width: 200px"
              placeholder="Поиск по вагонам">
            <el-button @click="searchData" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <el-table :data="value"
                  ref="filterTable"
                  height="450"
                  size="mini"
                  border>
          <el-table-column
              fixed
              type="index">
          </el-table-column>
          <el-table-column
              prop="vu20Date"
              label="Дата"
              :filters="globalFilter[key]['vu20Date']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="carNumber"
              label="Вагон"
              fixed
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="path"
              label="№ пути"
              fixed
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="osn"
              label="Осные"
              :filters="globalFilter[key]['osn']"
              :filter-method="filterHandler"
              width="50">
          </el-table-column>
          <el-table-column
              prop="vu20InGood"
              label="Вид прод."
              :filters="globalFilter[key]['vu20InGood']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="operation"
              label="Вид оп."
              :filters="globalFilter[key]['operation']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="operationType"
              label="Вид оп."
              :filters="globalFilter[key]['operationType']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="processingType"
              label="Вид опер."
              :filters="globalFilter[key]['processingType']"
              :filter-method="filterHandler"
              width="120">
          </el-table-column>
          <el-table-column
              prop="torText"
              label="ТОР"
              :filters="globalFilter[key]['torText']"
              :filter-method="filterHandler"
              width="50">
          </el-table-column>
          <el-table-column
              prop="drKrText"
              label="ТОР для Др,КР"
              :filters="globalFilter[key]['drKrText']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column label="Дата/Время ТОР">
            <el-table-column
                prop="vu22repairBeginDate"
                label="Начало"
                :filters="globalFilter[key]['vu22repairBeginDate']"
                :filter-method="filterHandler"
                width="100">
            </el-table-column>
            <el-table-column
                prop="vu22repairEndDate"
                label="Окончание"
                :filters="globalFilter[key]['vu22repairEndDate']"
                :filter-method="filterHandler"
                width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="vu20DocNumber"
              label="№ акта"
              :filters="globalFilter[key]['vu20DocNumber']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="vu20Master"
              label="Сменный мастер"
              :filters="globalFilter[key]['vu20Master']"
              :filter-method="filterHandler"
              width="120">
          </el-table-column>
          <el-table-column
              prop="senderCompany"
              label="Собственник"
              width="150">
          </el-table-column>
          <el-table-column
              prop="datePrib"
              label="Прибытие"
              :filters="globalFilter[key]['datePrib']"
              :filter-method="filterHandler"
              width="130">
          </el-table-column>
          <el-table-column
              prop="price"
              label="Расценка"
              :filters="globalFilter[key]['price']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="priceTor"
              label="Ст-ть ТОР"
              :filters="globalFilter[key]['priceTor']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
        </el-table>
        <p style="font-size: 12px; margin-top: 20px; text-align: right">
          Общее кол-во данных: {{ value.length }}
        </p>
      </el-tab-pane>
    </el-tabs>
    <el-table
        v-if="Object.keys(data).length > 1"
        :data="currentOutCome"
        size="mini"
    >
      <el-table-column
          prop="title"
          width="600"
          label="Наименование">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="Количество">
      </el-table-column>
      <el-table-column
          prop="price"
          label="Тариф">
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import date from '@/modules/directories/date/date'
import blockchainApi from '@/modules/api/blockchain'
import { format, parse } from 'date-fns'
import importExel from '@/modules/exel/register-common'

export default {
  name: 'RegisterBatys',
  data () {
    return {
      isLoading: false,
      fromDate: '',
      search: '',
      toDate: '',
      tab: '',
      reportSubtitle: '',
      documentInfo: {},
      data: {},
      dataAll: {},
      globalFilter: {},
      outComeData: {},
      currentOutCome: [],
      filterCarNumber: '',
      mainHeader: [
        { text: '№', value: 'index', width: 30, isNotFilter: true, rowspan: 2, colspan: 1 },
        { text: 'Дата', value: 'vu20Date', width: 100, rowspan: 2, colspan: 1 },
        { text: 'Вагон', value: 'carNumber', align: 'center', width: 80, isNotFilter: true, isLink: true, isSticky: true, rowspan: 2, colspan: 1 },
        { text: '№ пути', value: 'path', colspan: 1, rowspan: 2, width: 30 },
        { text: 'Осные', value: 'osn', align: 'center', width: 20, rowspan: 2, colspan: 1 },
        { text: 'Вид прод.', value: 'vu20InGood', colspan: 1, rowspan: 2, width: 150 },
        { text: 'Вид оп.', value: 'operation', colspan: 1, rowspan: 2, width: 150 },
        { text: 'Вид оп.', value: 'operationType', colspan: 1, rowspan: 2, width: 150 },
        { text: 'Вид обр.', value: 'processingType', colspan: 1, rowspan: 2, width: 150 },
        { text: 'ТОР', value: 'torText', align: 'center', width: 30, rowspan: 2, colspan: 1 },
        { text: 'ТОР для Др,КР', value: 'drKrText', colspan: 1, rowspan: 2, width: 60 },
        { text: 'Снятие знаков опасности', value: 'removalDangerSigns', colspan: 1, rowspan: 2, width: 60 },
        {
          text: 'Дата/Время ТОР',
          value: 'torDate',
          align: 'center',
          width: 290,
          colspan: 2,
          rowspan: 1,
          subheader: [
            { text: 'Начало', value: 'vu22repairBeginDate', colspan: 1, rowspan: 1, width: 145 },
            { text: 'Окончание', value: 'vu22repairEndDate', colspan: 1, rowspan: 1, width: 145 }]
        },
        { text: '№ акта', value: 'vu20DocNumber', width: 150, isLink: true, rowspan: 2, colspan: 1 },
        { text: 'Сменный мастер', value: 'vu20Master', width: 250, rowspan: 2, colspan: 1 },
        { text: 'Собственник', value: 'senderCompany', colspan: 1, rowspan: 2, width: 200 },
        { text: 'Прибытие', value: 'datePrib', width: 145, rowspan: 2, colspan: 1 },
        { text: 'Расценка', value: 'price', colspan: 1, rowspan: 2, width: 60 },
        { text: 'Ст-ть ТОР', value: 'priceTor', colspan: 1, rowspan: 2, width: 60 }
      ],
      headerOutcome: [
        { text: 'Наименование', value: 'title' },
        { text: 'Количество', value: 'amount' },
        { text: 'Тариф', value: 'price' }
      ],
      dataInfo: {
        'Подготовка 4-осн в/ц горячим способом в целях их подготовки ко всем видам ремонта': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом в целях их подготовки под плановый вид ремонта': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом из под темных под темные нефтепродукты': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом из под темных под светлые нефтепродукты': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом из под светлых под светлые нефтепродукты': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом из под светлого под нефтехимический нефтепродукты': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц под налив из под темных под темные нефтепродукты': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц под налив из под светлых под светлые нефтепродукты': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц под налив из под светлого под нефтехимический нефтепродукты': { amount: 0, price: 0 },
        'ТОР 4 осных в/ц': { amount: 0, price: 0 },
        'Снятие знаков опасности с котла цистерны': { amount: 0, price: 0 },
        summary: { title: 'Итого сумма', price: 0, bold: true }
      }
    }
  },
  created () {
    this.getDocumentByHash()
    this.data['Реестр'] = []
    // this.createHeader()
  },
  mounted () {
  },
  computed: {
    filteredData () {
      return this.tableData.filter(row => {
        return row.carNumber.toLowerCase().includes(this.filterCarNumber.toLowerCase())
      })
    },
    getHash () {
      return this.$route.query.hash
    }
  },
  watch: {
  },
  methods: {
    searchInput () {
      if (!this.search) {
        this.data = JSON.parse(JSON.stringify(this.dataAll))
      }
    },
    searchData () {
      if (!this.search) {
        this.data = JSON.parse(JSON.stringify(this.dataAll))
      } else {
        for (const key in this.data) {
          this.data[key] = this.dataAll[key].filter(item => item.carNumber.includes(this.search))
        }
        this.data = JSON.parse(JSON.stringify(this.data))
      }
      console.log(this.data)
    },
    clearFilter () {
      this.$refs.filterTable.forEach(ref => {
        ref.clearFilter()
      })
    },
    createHeader () {
      this.mainHeader = this.mainHeader.map(head => {
        if (head.value === 'senderCompany') {
          head.input.band = this.sendersJournal
        }
        return head
      })
      this.subheader = []
      this.header = []
      this.mainHeader.forEach(head => {
        if (head.subheader) {
          this.subheader.push(...head.subheader)
          this.header.push(...head.subheader)
        } else {
          this.header.push(head)
        }
      })
    },
    filterHandler (value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    tabClick (tab, event) {
      this.currentOutCome = this.outComeData[tab.name]
    },
    getDocumentByHash () {
      this.isLoading = true
      const dataInfo = JSON.parse(JSON.stringify(this.dataInfo))
      blockchainApi.getDocumentByHash(this.getHash).then((resp) => {
        const dataSet = JSON.parse(resp.data.data)
        this.fromDate = date.getFormattedDate(dataSet.meta.fromDate)
        this.toDate = date.getFormattedDate(dataSet.meta.toDate)
        this.reportSubtitle = 'РЕЕСТР арендованных и  собственных вагон-цистерн компании,<br/>' +
            'проходящих осмотр котлов вагонов/цистерн и горячую обработку, а также ТОР<br/>' +
            'в ТОО "Ертыс-Сервис" c ' + this.fromDate + 'г.' + ' по ' + this.toDate
        this.documentInfo = resp.data
        const response = JSON.parse(resp.data.data).body

        const data = {}
        data['Реестр'] = []
        const outComeData = {}
        outComeData['Реестр'] = []

        response.forEach((item) => {
          const key = item.owner ? item.owner.title : 'Без собственника'
          data[key] = []
          outComeData[key] = item.itog
            .filter(el => el.amount > 0)
            .map(el => {
              el.price = +el.price.toFixed(2)
              return el
            })
          item.subResp.map((item, i) => {
            const { railcar, vu20, vu19, vu23, vu36, processing } = item
            item.carNumber = railcar.carNumber
            item.uniqId = `${key}${i}${railcar.carNumber}`
            item.path = item.pathItem ? item.pathItem.title : ''
            item.osn = '4-х'
            let vu20InGood
            if (vu20) {
              item.vu20Date = this.getFormattedDate(vu20.createdDate)
              item.vu20DocNumber = vu20.docNum
              item.vu20Master = vu20.master ? vu20.master.map(x => x.title).join(', ') : ''
              item.vu20InGood = vu20.inGood ? vu20.inGood.title : ''
              vu20InGood = vu20.inGood ? vu20.inGood.title : ''
            }
            if (!item.vu20Date && vu19) {
              item.vu20Date = this.getFormattedDate(vu19.createdDate)
            }
            if (!item.vu20InGood && processing) {
              item.vu20InGood = processing.inGood ? processing.inGood.title : ''
              vu20InGood = processing.inGood ? processing.inGood.title : ''
            }
            let op
            if (item.outGoodParent || item.inGoodParent) {
              if (item.outGoodParent === 'нефтехимия' || item.inGoodParent === 'нефтехимия') {
                item.operation = 'нефтехим'
                op = 'нефтехим'
              } else {
                op = (item.outGoodParent ? item.outGoodParent : '-') + '/' + (item.inGoodParent ? item.inGoodParent : '-')
                item.operation = (item.outGoodParent ? item.outGoodParent : '-') + '/' + (item.inGoodParent ? item.inGoodParent : '-')
              }
            }
            if (item.drKr) {
              item.drKrText = 'для ДР,КР'
              item.processingType = 'Горячая обработка'
              item.operationType = op
              item.operation = 'под план.рем'
              item.vu20InGood = ''
            } else if (vu20 && vu20.processingType) {
              item.processingType = vu20.processingType ? vu20.processingType.title : ''
            }
            if (item.tor) {
              item.operationType = op
              item.operation = 'подг.к рем'
              item.torText = 'ТОР'
            }
            if (item.current) {
              item.operationType = 'текущий'
              item.torText = ''
              item.drKrText = ''
              item.vu20InGood = ''
              item.operation = op
            }
            if (processing) {
              item.senderCompany = processing.senderCompany ? processing.senderCompany.title : ''
            }
            if (item.vu20Current) {
              item.processingType = 'С текущего'
              item.operation = op
              item.operationType = ''
              item.drKrText = ''
              item.vu20InGood = vu20InGood
            }
            if (vu23) {
              item.vu22repairBeginDate = this.getFormattedDate(vu23.createdDate)
            }
            if (vu36) {
              item.vu22repairEndDate = this.getFormattedDate(vu36.createdDate)
            }
            if (!item.vu20Master && vu19) {
              item.vu20Master = vu19.master ? vu19.master.map(x => x.title).join(', ') : ''
            }
            item.datePrib = this.getFormattedDate(item.dataPeregona)
            data[key].push(item)
            data['Реестр'].push(item)
          })
        })

        this.data = data
        this.outComeData = outComeData
        for (const key in this.outComeData) {
          const sumPrice = { title: 'Итого сумма', price: 0, bold: true }
          this.outComeData[key].forEach(item => {
            dataInfo[item.title].title = item.title
            dataInfo[item.title].amount = this.rounded(item.amount + dataInfo[item.title].amount)
            // dataInfo.summary.amount = this.rounded(item.amount + dataInfo.summary.amount)
            dataInfo[item.title].price = this.rounded(item.price + dataInfo[item.title].price)
            dataInfo.summary.price = this.rounded(item.price + dataInfo.summary.price)
            // sumPrice.amount = this.rounded(item.amount + sumPrice.amount)
            sumPrice.price = this.rounded(item.price + sumPrice.price)
          })
          this.outComeData[key].push(sumPrice)
        }
        this.outComeData['Реестр'] = Object.values(dataInfo).filter(item => item.title)
        this.currentOutCome = this.outComeData['Реестр']
        for (const key in this.data) {
          this.data[key] = this.sortRailcars(this.data[key])
        }
        this.tab = Object.keys(this.data)[0]
        this.dataAll = JSON.parse(JSON.stringify(this.data))
        this.updateFilters()
      }).finally(() => {
        this.isLoading = false
      })
    },
    rounded (number) {
      return +number.toFixed(2)
    },
    sortRailcars (railcars) {
      return railcars.sort((a, b) => {
        let value
        if (!b.vu20Date) {
          value = -1
        } else if (!a.vu20Date) {
          value = 1
        } else {
          value = parse(a.vu20Date, 'dd.MM.yyyy HH:mm', new Date()) - parse(b.vu20Date, 'dd.MM.yyyy HH:mm', new Date())
        }
        if (value === 0) {
          value = Number.parseInt(a.vu20DocNumber) - Number.parseInt(b.vu20DocNumber)
        }
        return value
      }).map((item, i) => {
        item.index = i + 1
        return item
      })
    },
    updateFilters () {
      for (const key in this.data) {
        this.globalFilter[key] = {}
        this.mainHeader.forEach(head => {
          if (!head.isNotFilter) {
            const headKey = head.value
            const setters = {}
            setters[headKey] = new Set()
            this.data[key].forEach((item) => {
              setters[headKey].add(item[headKey])
            })
            this.globalFilter[key][headKey] = Array.from(setters[headKey]).map(i => ({ text: i, value: i }))
          }
          if (head.subheader) {
            head.subheader.forEach(sub => {
              const headKey = sub.value
              const setters = {}
              setters[headKey] = new Set()
              this.data[key].forEach((item) => {
                setters[headKey].add(item[headKey])
              })
              this.globalFilter[key][headKey] = Array.from(setters[headKey]).map(i => ({ text: i, value: i }))
            })
          }
        })
      }
    },
    createNewItem (item) {
      try {
        const newRailcar = item
        if (newRailcar.vu23 && newRailcar.vu23.typeRepairNewVu23 && newRailcar.vu23.typeRepairNewVu23.length > 0) {
          newRailcar.vu23.typeRepairNewVu23.forEach(i => {
            if (i.title.includes('ВРД') || i.title.includes('ВРЗ')) {
              if (newRailcar.vu23.outGoodVu23) {
                newRailcar.typeRepairVu23 = newRailcar.vu23.outGoodVu23.title
              }
            }
          })
        }
        newRailcar.carNumber = newRailcar.railcar.carNumber
        newRailcar.osn = '4-х'
        newRailcar.torText = item.tor ? 'ТОР' : ''
        newRailcar.vrdText = item.vrd ? 'для Др,КР' : ''
        newRailcar.dateDepartureText = this.getFormattedDate(item.dateDeparture)
        if (item.inGoodParent || item.outGoodParent) {
          newRailcar.operation = (item.outGoodParent ? item.outGoodParent.shortTitle : '-') + '/' + (item.inGoodParent ? item.inGoodParent.shortTitle : '-')
          if (newRailcar.operation === 'св/нб' || newRailcar.operation === 'тем/нб' || newRailcar.operation === 'св/тем' || newRailcar.operation === 'св/Битум') {
            newRailcar.operation = 'тем/тем'
          }
        }
        if (item.du1Main) {
          newRailcar.du1MainCreatedDate = this.getFormattedDate(item.du1Main.createdDate)
        }
        if (item.vu20) {
          newRailcar.vu20DocNum = item.vu20.docNum ? item.vu20.docNum : ''
          newRailcar.vu20CreatedDate = this.getFormattedDate(item.vu20.createdDate)
          newRailcar.product = (item.vu20.outGood ? item.vu20.outGood.title : '-') + '/' + (item.vu20.inGood ? item.vu20.inGood.title : '-')
          if (item.vu20.processingType && item.vu20.processingType.title) {
            newRailcar.coldProcessing = item.vu20.processingType.title.includes('Холодн') ? 'хол' : ''
            newRailcar.hotProcessing = item.vu20.processingType.title.includes('Горячая') ? 'гор' : ''
          }
          newRailcar.master = item.vu20.master ? item.vu20.master.map(x => x.title).join(', ') : ''
          if (item.vu20.startTime20) {
            newRailcar.startTimePodNaliv = format(new Date(item.vu20.startTime20), 'HH:mm:ss')
          }
          if (item.vu20.endTime20) {
            newRailcar.endTimePodNaliv = format(new Date(item.vu20.endTime20), 'HH:mm:ss')
          }
          if (item.vu20.startTime20 && item.vu20.endTime20) {
            let diffSecond = ((new Date(item.vu20.endTime20)).getTime() - (new Date(item.vu20.startTime20)).getTime())
            newRailcar.diffTimePodNaliv = this.parseToTime(diffSecond)
            if (item.timeStandardInSec) {
              diffSecond = (item.timeStandardInSec * 1000) - diffSecond
              newRailcar.diffStandardTime = this.parseToTime(diffSecond)
            }
          }
        }
        newRailcar.standardText = this.parseToTime(item.timeStandardInSec * 1000)
        if (item.vu19) {
          newRailcar.vu19DocNum = item.vu19.docNum ? item.vu19.docNum : ''
          newRailcar.vu19CreatedDate = this.getFormattedDate(item.vu19.createdDate)
          newRailcar.vu19Master = item.vu19.master ? item.vu19.master.map(x => x.title).join(', ') : ''
        }
        if (item.vu36Resp) {
          newRailcar.startTorDate = this.getFormattedDate(item.vu36Resp.vu36BeginDate)
          newRailcar.endTorDate = this.getFormattedDate(item.vu36Resp.vu36EndDate)
        }
        // если ву-20 отсутствует заполнить данными из ву-19
        newRailcar.vu20DocNum = newRailcar.vu20DocNum ? newRailcar.vu20DocNum : newRailcar.vu19DocNum
        newRailcar.master = newRailcar.master ? newRailcar.master : newRailcar.vu19Master
        newRailcar.vu20CreatedDate = item.vrd ? newRailcar.vu19CreatedDate : newRailcar.vu20CreatedDate
        newRailcar.vu20CreatedDate = newRailcar.vu20CreatedDate ? newRailcar.vu20CreatedDate.slice(0, 10) : ''
        newRailcar.hotProcessing = item.vrd ? 'гор' : newRailcar.hotProcessing
        return newRailcar
      } catch (e) {
        console.log(e)
      }
    },
    createExel () {
      const fileName = 'Реестр арендованных и  собственных вагон-цистерн ' + 'c ' + this.fromDate.slice(0, 10) + 'г.' + ' по ' + this.toDate.slice(0, 10)
      const newDate = JSON.parse(JSON.stringify(this.data))
      for (const key in newDate) {
        // eslint-disable-next-line no-prototype-builtins
        if (!newDate.hasOwnProperty(key)) {
          continue
        }
        newDate[key].forEach((x, i) => {
          x.index = i + 1
          x.senderCompany = x.senderCompany ? x.senderCompany.title : ''
        })
      }
      importExel(fileName, this.mainHeader, newDate, this.outComeData, this.headerOutcome, 'c ' + this.fromDate.slice(0, 10) + 'г.' + ' по ' + this.toDate.slice(0, 10))
    },
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    getFormattedDate (str) {
      return date.getFormattedDate(str)
    },
    parseToTime (str) {
      return date.parseToTime(str)
    }
  }
}
</script>

<style scoped>

</style>
