<template>
  <el-card v-loading="isLoading">
    <el-button @click="createExel">
      <i class="el-icon-download" style="font-weight: bold"/>
      Excel
    </el-button>
    <div class="text-center mb-4 mt-lg-n6 text--secondary font-weight-bold" style="text-align:center; width: 100%; font-size: 15px; margin: 30px 0; font-weight: bolder" v-html="reportSubtitle"></div>
    <el-tabs v-if="Object.keys(data).length > 1" v-model="tab" @tab-click="tabClick">
      <el-tab-pane
          v-for="(value, key) in data"
          :label="key"
          :name="key"
          :key="key">
        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <el-button @click="clearFilter()" size="mini">Сбросить все фильтра</el-button>
          <el-input
              v-model="search"
              @input="searchInput"
              style="width: 200px"
              placeholder="Поиск по вагонам">
            <el-button @click="searchData" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <el-table :data="value"
                  ref="filterTable"
                  height="450"
                  size="mini"
                  border>
          <el-table-column
              fixed
              type="index">
          </el-table-column>
          <el-table-column
              prop="vu20CreatedDate"
              label="Дата"
              :filters="globalFilter[key]['vu20Date']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="carNumber"
              label="Вагон"
              fixed
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="osn"
              label="Осные"
              :filters="globalFilter[key]['osn']"
              :filter-method="filterHandler"
              width="50">
          </el-table-column>
          <el-table-column
              prop="product"
              label="Вид прод."
              :filters="globalFilter[key]['product']"
              :filter-method="filterHandler"
              width="180">
          </el-table-column>
          <el-table-column
              prop="operation"
              label="Вид опер."
              :filters="globalFilter[key]['gostOperation20a']"
              :filter-method="filterHandler"
              width="120">
          </el-table-column>
          <el-table-column label="Вид обр.">
            <el-table-column
                prop="coldProcessing"
                label="Хол"
                :filters="globalFilter[key]['coldProcessingType']"
                :filter-method="filterHandler"
                width="50">
            </el-table-column>
            <el-table-column
                prop="hotProcessing"
                label="Гор"
                :filters="globalFilter[key]['hotProcessingType']"
                :filter-method="filterHandler"
                width="50">
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="torText"
              label="ТОР"
              :filters="globalFilter[key]['torText']"
              :filter-method="filterHandler"
              width="50">
          </el-table-column>
          <el-table-column
              prop="internalAndExternalDrKrText"
              label="ДР,КР с наружкой"
              :filters="globalFilter[key]['internalAndExternalDrKrText']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="internalDrKrText"
              label="ДР,КР без наружки"
              :filters="globalFilter[key]['internalDrKrText']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="stencilReplacementText"
              label="Замена трафарета на мет. номер"
              :filters="globalFilter[key]['stencilReplacementText']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="cleaningBoilersText"
              label="Наружная очистка котлов"
              :filters="globalFilter[key]['cleaningBoilersText']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="vu20Num"
              label="№ акта"
              :filters="globalFilter[key]['vu20Num']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="vu20Master"
              label="Сменный мастер"
              :filters="globalFilter[key]['vu20Master']"
              :filter-method="filterHandler"
              width="120">
          </el-table-column>
          <el-table-column
              prop="du1MainDate"
              label="Прибытие"
              :filters="globalFilter[key]['du1MainDate']"
              :filter-method="filterHandler"
              width="130">
          </el-table-column>
          <el-table-column
              prop="priceService"
              label="Ст-ть услуги"
              :filters="globalFilter[key]['priceService']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="priceDrKrNeftebit"
              label="ДР,КР из под нефтебитума"
              :filters="globalFilter[key]['priceDrKrNeftebit']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="priceTOR"
              label="Ст-ть ТОР"
              :filters="globalFilter[key]['priceTOR']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="clearingDangerSigns"
              label="Очистка от знака опасности"
              :filters="globalFilter[key]['clearingDangerSigns']"
              :filter-method="filterHandler"
              width="130">
          </el-table-column>
          <el-table-column
              prop="senderCompany.title"
              label="Собственник"
              width="150">
          </el-table-column>
        </el-table>
        <p style="font-size: 12px; margin-top: 20px; text-align: right">
          Общее кол-во данных: {{ value.length }}
        </p>
      </el-tab-pane>
    </el-tabs>
    <el-table
        v-if="Object.keys(data).length > 1"
        :data="currOutcomeData"
        size="mini"
    >
      <el-table-column
          prop="name"
          width="600"
          label="Наименование">
      </el-table-column>
      <el-table-column
          prop="quantity"
          label="Количество">
      </el-table-column>
      <el-table-column
          prop="price"
          label="Тариф">
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import date from '@/modules/directories/date/date'
import blockchainApi from '@/modules/api/blockchain'
import { format, parse, startOfDay } from 'date-fns'
import importExel from '@/modules/exel/register-kaz'

export default {
  name: 'RegisterKazykurt',
  data () {
    return {
      isLoading: false,
      fromDate: '',
      search: '',
      toDate: '',
      tab: '',
      reportSubtitle: '',
      documentInfo: {},
      data: {},
      dataAll: {},
      globalFilter: {},
      outComeData: {},
      currentOutCome: [],
      filterCarNumber: '',
      mainHeader: [
        { text: '№', value: 'index', colspan: 1, rowspan: 2, width: 40, isNotFilter: true },
        { text: 'Дата', value: 'vu20Date', colspan: 1, rowspan: 2, width: 100 },
        { text: 'Вагон', value: 'carNumber', align: 'center', colspan: 1, rowspan: 2, width: 80, isNotFilter: true, isLink: true, isSticky: true },
        { text: 'Осные', value: 'osn', align: 'center', colspan: 1, rowspan: 2, width: 20 },
        { text: 'Вид прод.', value: 'product', width: 100, colspan: 1, rowspan: 2 },
        { text: 'Вид оп.', value: 'gostOperation20a', width: 50, colspan: 1, rowspan: 2 },
        {
          text: 'Вид обр.',
          value: 'processingType',
          align: 'center',
          width: 40,
          rowspan: 1,
          colspan: 2,
          subheader: [
            { text: 'Хол', value: 'coldProcessingType', colspan: 1, rowspan: 1, align: 'center', width: 30 },
            { text: 'Гор', value: 'hotProcessingType', colspan: 1, rowspan: 1, width: 30 }]
        },
        { text: 'ТОР', value: 'torText', align: 'center', width: 30, rowspan: 2, colspan: 1 },
        { text: 'ДР,КР с наружкой', value: 'internalAndExternalDrKrText', width: 40, colspan: 1, rowspan: 2 },
        { text: 'ДР,КР без наружки', value: 'internalDrKrText', width: 20, colspan: 1, rowspan: 2 },
        { text: 'Замена трафарета на мет. номер', value: 'stencilReplacementText', width: 40, colspan: 1, rowspan: 2 },
        { text: 'Наружная очистка котлов', value: 'cleaningBoilersText', width: 40, colspan: 1, rowspan: 2 },
        { text: '№ акта', value: 'vu20Num', width: 40, colspan: 1, rowspan: 2 },
        { text: 'Сменный мастер', value: 'vu20Master', width: 200, colspan: 1, rowspan: 2 },
        { text: 'Прибытие', value: 'du1MainDate', colspan: 1, rowspan: 2, width: 100 },
        { text: 'Ст-ть услуги', value: 'priceService', width: 80, colspan: 1, rowspan: 2 },
        { text: 'ДР,КР из под нефтебитума', value: 'priceDrKrNeftebit', width: 80, colspan: 1, rowspan: 2 },
        { text: 'Ст-ть ТОР', value: 'priceTOR', width: 80, colspan: 1, rowspan: 2 },
        { text: 'Очистка от знака опасности', value: 'clearingDangerSigns', width: 80, colspan: 1, rowspan: 2 },
        { text: 'Собственник', value: 'senderCompany', width: 250, colspan: 1, rowspan: 2, isNotFilter: true }
      ],
      outcomeData: {},
      currOutcomeData: [],
      dataInfo: {
        summQuantity: { name: 'Всего обработано вагонов - цистерн всех собственников по видам операций', quantity: 0, price: ' ', bold: true },
        neftebitumNaT: { name: 'Подготовка цистерн из-под нефтебитума под налив темных нефтепродуктов горячей обработкой', quantity: 0, price: 0 },
        ss: { name: 'Подготовка цистерн из-под светлого продукта под светлый горячей обработкой', quantity: 0, price: 0 },
        ts: { name: 'Подготовка цистерн из-под темного продукта под светлый горячей обработкой', quantity: 0, price: 0 },
        tt: { name: 'Подготовка цистерн из-под темного продукта под темный горячей обработкой', quantity: 0, price: 0 },
        preparationCurrentRepair: { name: 'Подготовка цистерн под текущий ремонт горячей обработкой', quantity: 0, price: 0 },
        coldProcessingBoilers: { name: 'Холодная обработка котлов вагонов-цистерн', quantity: 0, price: 0 },
        preparationAllTypesRepairsSS: { name: 'Подготовка цистерн под плановый вид ремонта с наружной очистки котлов горячей обработкой св/св ', quantity: 0, price: 0 },
        preparationAllTypesRepairsTT: { name: 'Подготовка цистерн под плановый вид ремонта с наружной очисткой котлов горячей обработкой тем/тем', quantity: 0, price: 0 },
        repairsWithoutCleaningBoilersSS: { name: 'Подготовка цистерн под плановый вид ремонта без наружной очистки котлов горячей обработкой св/св', quantity: 0, price: 0 },
        repairsWithoutCleaningBoilersTT: { name: 'Подготовка цистерн под плановый вид ремонта без наружной очистки котлов горячей обработкой тем/тем', quantity: 0, price: 0 },
        neftebitumWithoutCleaningBoilers: { name: 'Подготовка цистерн под плановый вид ремонта из под нефтебитума без наружной очистки котлов', quantity: 0, price: 0 },
        uncouplingRepairBoilers: { name: 'Текущий отцепочный ремонт горячей обработкой', quantity: 0, price: 0 },
        clearingDangerSign: { name: 'Очистка от знака опасности', quantity: 0, price: 0 },
        replacingStencilNumber: { name: 'Замена трафарета номера на металлический номер', quantity: 0, price: 0 },
        externalCleaningBoilers: { name: 'Наружная очистка котлов вагонов-цистерн', quantity: 0, price: 0 },
        sumPrice: { name: 'Итого сумма', quantity: ' ', price: 0, bold: true }
      },
      headerOutcome: [
        { text: 'Наименование', value: 'name' },
        { text: 'Количество', value: 'quantity' },
        { text: 'Тариф', value: 'price' }
      ]
    }
  },
  created () {
    this.getDocumentByHash()
    // this.createHeader()
  },
  mounted () {
  },
  computed: {
    filteredData () {
      return this.tableData.filter(row => {
        return row.carNumber.toLowerCase().includes(this.filterCarNumber.toLowerCase())
      })
    },
    getHash () {
      return this.$route.query.hash
    }
  },
  watch: {
  },
  methods: {
    searchInput () {
      if (!this.search) {
        this.data = JSON.parse(JSON.stringify(this.dataAll))
      }
    },
    searchData () {
      if (!this.search) {
        this.data = JSON.parse(JSON.stringify(this.dataAll))
      } else {
        for (const key in this.data) {
          this.data[key] = this.dataAll[key].filter(item => item.carNumber.includes(this.search))
        }
        this.data = JSON.parse(JSON.stringify(this.data))
      }
      console.log(this.data)
    },
    clearFilter () {
      this.$refs.filterTable.forEach(ref => {
        ref.clearFilter()
      })
    },
    createHeader () {
      this.mainHeader = this.mainHeader.map(head => {
        if (head.value === 'senderCompany') {
          head.input.band = this.sendersJournal
        }
        return head
      })
      this.subheader = []
      this.header = []
      this.mainHeader.forEach(head => {
        if (head.subheader) {
          this.subheader.push(...head.subheader)
          this.header.push(...head.subheader)
        } else {
          this.header.push(head)
        }
      })
    },
    filterHandler (value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    tabClick (tab, event) {
      this.currentOutCome = this.outComeData[tab.name]
    },
    getDocumentByHash () {
      this.isLoading = true
      // const dataInfo = JSON.parse(JSON.stringify(this.dataInfo))
      blockchainApi.getDocumentByHash(this.getHash).then((resp) => {
        this.data = { 'Общий реестр': [] }
        this.outcomeData = {}
        this.currOutcomeData = []
        const dataSet = JSON.parse(resp.data.data)
        this.fromDate = date.getFormattedDate(dataSet.meta.fromDate)
        this.toDate = date.getFormattedDate(dataSet.meta.toDate)
        this.reportSubtitle = 'РЕЕСТР арендованных и  собственных вагон-цистерн компании,<br/>' +
            'проходящих осмотр котлов вагонов/цистерн и горячую обработку, а также ТОР<br/>' +
            'в ТОО "Ертыс-Сервис" c ' + this.fromDate + 'г.' + ' по ' + this.toDate
        this.documentInfo = resp.data
        const data = JSON.parse(resp.data.data).body
        const info = data.resp
        if (info && info.length > 0) {
          this.outcomeData = { 'Общий реестр': this.createOutcome(data) }
          info.forEach(item => {
            const key = item.owner ? item.owner.title.trim() : 'Без собственника'
            this.outcomeData[key] = this.createOutcome(item, key)
            // eslint-disable-next-line no-prototype-builtins
            if (!this.data.hasOwnProperty(key)) {
              this.data[key] = []
            }
            item['subResp'].forEach(railcar => {
              const newRailcar = this.createDataTable(railcar)
              this.data[key].push(newRailcar)
              this.data['Общий реестр'].push(JSON.parse(JSON.stringify(newRailcar)))
            })
          })
          for (const key in this.data) {
            this.data[key] = this.changeTable(this.data[key])
          }
          this.currOutcomeData = [...this.outcomeData['Общий реестр']]
        }
        this.tab = Object.keys(this.data)[0]
        this.dataAll = JSON.parse(JSON.stringify(this.data))
        this.updateFilters()
      }).finally(() => {
        this.isLoading = false
      })
    },
    changeTable (railcars) {
      return this.sortRailcars(railcars).map((x, index) => {
        x.index = index + 1
        return x
      })
    },
    createDataTable (railcar) {
      try {
        railcar.carNumber = railcar.railcar ? railcar.railcar.carNumber : ''
        railcar.osn = '4'
        railcar.torText = railcar.tor ? 'ТОР' : ''
        railcar.internalAndExternalDrKrText = railcar.internalAndExternalDrKr ? 'ДР,КР' : ''
        railcar.internalDrKrText = railcar.internalDrKr ? 'ДР,КР' : ''
        railcar.stencilReplacementText = railcar.stencilReplacement ? '36354.96' : ''
        if (new Date(railcar.du1Main.createdDate) > new Date(2024, 4, 1, 0, 0, 0, 0)) {
          railcar.cleaningBoilersText = railcar.priceExternalCleaningTicket
        } else {
          railcar.cleaningBoilersText = railcar.cleaningBoilers ? 'Очистка' : ''
        }
        if (railcar.du1Main) {
          railcar.du1MainDate = railcar.du1Main.createdDate ? this.getFormattedDate(railcar.du1Main.createdDate).slice(0, 10) : ''
        }
        if (railcar.processing && railcar.processing.senderCompany) {
          railcar.senderCompany = railcar.processing.senderCompany
        }
        if (!railcar.senderCompany && railcar.senderCompanyForReestr) {
          railcar.senderCompany = railcar.senderCompanyForReestr
        }
        if (!railcar.senderCompany && railcar.drkrSenderCompany) {
          railcar.senderCompany = railcar.drkrSenderCompany
        }
        if (railcar.internalAndExternalDrKr || railcar.internalDrKr) {
          railcar.hotProcessingType = 'ГОР'
          if (railcar.outGood && railcar.inGood) {
            if (railcar.outGood.productParentType.key === 'dark' && railcar.inGood.productParentType.key === 'dark') {
              railcar.gostOperation20a = '3'
            } else if (railcar.outGood.productParentType.key === 'neftehim' && railcar.inGood.productParentType.key === 'neftehim') {
              railcar.gostOperation20a = '3'
            } else if (railcar.outGood.productParentType.key === 'light' && railcar.inGood.productParentType.key === 'light') {
              railcar.gostOperation20a = '2'
            }
          }
        } else {
          railcar.hotProcessingType = ''
          railcar.gostOperation20a = ''
        }
        if (railcar.vu20) {
          const { docNum, createdDate, master, processingType, gostOperation20a } = railcar.vu20
          railcar.vu20Date = createdDate ? this.getFormattedDate(createdDate).slice(0, 10) : ''
          railcar.vu20Master = master ? master.map(x => x.title).join(', ') : ''
          railcar.vu20Num = docNum || ''
          railcar.gostOperation20a = gostOperation20a ? gostOperation20a.title : ''
          if (processingType) {
            railcar.hotProcessingType = processingType.title.includes('Гор') ? 'ГОР' : ''
            railcar.coldProcessingType = processingType.title.includes('Хол') ? 'ХОЛ' : ''
          }
        }
        if (railcar.vu19) {
          const { docNum, createdDate, master } = railcar.vu19
          if (!railcar.vu20Date && createdDate) {
            railcar.vu20Date = this.getFormattedDate(railcar.vu19.createdDate).slice(0, 10)
          }
          if (!railcar.vu20Master && master) {
            railcar.vu20Master = master.map(x => x.title).join(', ')
          }
          if (!railcar.vu20Num && docNum) {
            railcar.vu20Num = docNum
          }
        }
        if (railcar.outGood && railcar.inGood) {
          railcar.product =
              (railcar.outGood ? railcar.outGood.shortCodeName : '-') +
              '/' +
              (railcar.inGood ? railcar.inGood.shortCodeName : '-')
        } else if (railcar.outGood || railcar.inGood) {
          railcar.product = railcar.outGood ? railcar.outGood.title : railcar.inGood ? railcar.inGood.title : ''
        }
        return railcar
      } catch (err) {
        console.log('err', err)
      }
    },
    createOutcome (info, key = '') {
      if (key) {
        this.dataInfo.summQuantity.name = `Всего обработано вагонов - собственных вагон-цистерн компании ${key} по видам операций`
      } else {
        this.dataInfo.summQuantity.name = 'Всего обработано вагонов - цистерн всех собственников по видам операций'
      }
      const data = JSON.parse(JSON.stringify(this.dataInfo))
      data.summQuantity.quantity = 0
      data.sumPrice.price = 0
      for (const key in info) {
        // eslint-disable-next-line no-prototype-builtins
        if (info.hasOwnProperty(key) && data.hasOwnProperty(key)) {
          data[key].price = info[key].price
          data[key].quantity = info[key].quantity
          data.sumPrice.price += (info[key].quantity * info[key].price)
          if (key === 'clearingDangerSign') {
            data.summQuantity.quantity = info[key].quantity
          }
        }
      }
      data.sumPrice.price = data.sumPrice.price.toFixed(2)
      return Object.values(data).filter(x => x.quantity !== 0)
    },
    rounded (number) {
      return +number.toFixed(2)
    },
    sortRailcars (railcars) {
      return railcars.sort((a, b) => {
        let value
        // Сортировка по дате Ву-20
        if (!b.vu20Date) {
          value = -1
        } else {
          value = startOfDay(parse(a.vu20Date, 'dd.MM.yyyy', new Date())) - startOfDay(parse(b.vu20Date, 'dd.MM.yyyy', new Date()))
        }
        // Если даты равны, то по номеру Ву-20
        if (value === 0) {
          value = Number.parseInt(a.vu20Num) - Number.parseInt(b.vu20Num)
        }
        // Если номера равны , то по типу обработки и продукту
        if (value === 0) {
          if (a.hotProcessingType && !b.hotProcessingType) {
            value = -1
          } else if (b.hotProcessingType && !a.hotProcessingType) {
            value = 1
          } else {
            value = a.product < b.product ? -1 : 1
          }
        }
        return value
      })
    },
    updateFilters () {
      for (const key in this.data) {
        this.globalFilter[key] = {}
        this.mainHeader.forEach(head => {
          if (!head.isNotFilter) {
            const headKey = head.value
            const setters = {}
            setters[headKey] = new Set()
            this.data[key].forEach((item) => {
              setters[headKey].add(item[headKey])
            })
            this.globalFilter[key][headKey] = Array.from(setters[headKey]).map(i => ({ text: i, value: i }))
          }
          if (head.subheader) {
            head.subheader.forEach(sub => {
              const headKey = sub.value
              const setters = {}
              setters[headKey] = new Set()
              this.data[key].forEach((item) => {
                setters[headKey].add(item[headKey])
              })
              this.globalFilter[key][headKey] = Array.from(setters[headKey]).map(i => ({ text: i, value: i }))
            })
          }
        })
      }
    },
    createNewItem (item) {
      try {
        const newRailcar = item
        if (newRailcar.vu23 && newRailcar.vu23.typeRepairNewVu23 && newRailcar.vu23.typeRepairNewVu23.length > 0) {
          newRailcar.vu23.typeRepairNewVu23.forEach(i => {
            if (i.title.includes('ВРД') || i.title.includes('ВРЗ')) {
              if (newRailcar.vu23.outGoodVu23) {
                newRailcar.typeRepairVu23 = newRailcar.vu23.outGoodVu23.title
              }
            }
          })
        }
        newRailcar.carNumber = newRailcar.railcar.carNumber
        newRailcar.osn = '4-х'
        newRailcar.torText = item.tor ? 'ТОР' : ''
        newRailcar.vrdText = item.vrd ? 'для Др,КР' : ''
        newRailcar.dateDepartureText = this.getFormattedDate(item.dateDeparture)
        if (item.inGoodParent || item.outGoodParent) {
          newRailcar.operation = (item.outGoodParent ? item.outGoodParent.shortTitle : '-') + '/' + (item.inGoodParent ? item.inGoodParent.shortTitle : '-')
          if (newRailcar.operation === 'св/нб' || newRailcar.operation === 'тем/нб' || newRailcar.operation === 'св/тем' || newRailcar.operation === 'св/Битум') {
            newRailcar.operation = 'тем/тем'
          }
        }
        if (item.du1Main) {
          newRailcar.du1MainCreatedDate = this.getFormattedDate(item.du1Main.createdDate)
        }
        if (item.vu20) {
          newRailcar.vu20DocNum = item.vu20.docNum ? item.vu20.docNum : ''
          newRailcar.vu20CreatedDate = this.getFormattedDate(item.vu20.createdDate)
          newRailcar.product = (item.vu20.outGood ? item.vu20.outGood.title : '-') + '/' + (item.vu20.inGood ? item.vu20.inGood.title : '-')
          if (item.vu20.processingType && item.vu20.processingType.title) {
            newRailcar.coldProcessing = item.vu20.processingType.title.includes('Холодн') ? 'хол' : ''
            newRailcar.hotProcessing = item.vu20.processingType.title.includes('Горячая') ? 'гор' : ''
          }
          newRailcar.master = item.vu20.master ? item.vu20.master.map(x => x.title).join(', ') : ''
          if (item.vu20.startTime20) {
            newRailcar.startTimePodNaliv = format(new Date(item.vu20.startTime20), 'HH:mm:ss')
          }
          if (item.vu20.endTime20) {
            newRailcar.endTimePodNaliv = format(new Date(item.vu20.endTime20), 'HH:mm:ss')
          }
          if (item.vu20.startTime20 && item.vu20.endTime20) {
            let diffSecond = ((new Date(item.vu20.endTime20)).getTime() - (new Date(item.vu20.startTime20)).getTime())
            newRailcar.diffTimePodNaliv = this.parseToTime(diffSecond)
            if (item.timeStandardInSec) {
              diffSecond = (item.timeStandardInSec * 1000) - diffSecond
              newRailcar.diffStandardTime = this.parseToTime(diffSecond)
            }
          }
        }
        newRailcar.standardText = this.parseToTime(item.timeStandardInSec * 1000)
        if (item.vu19) {
          newRailcar.vu19DocNum = item.vu19.docNum ? item.vu19.docNum : ''
          newRailcar.vu19CreatedDate = this.getFormattedDate(item.vu19.createdDate)
          newRailcar.vu19Master = item.vu19.master ? item.vu19.master.map(x => x.title).join(', ') : ''
        }
        if (item.vu36Resp) {
          newRailcar.startTorDate = this.getFormattedDate(item.vu36Resp.vu36BeginDate)
          newRailcar.endTorDate = this.getFormattedDate(item.vu36Resp.vu36EndDate)
        }
        // если ву-20 отсутствует заполнить данными из ву-19
        newRailcar.vu20DocNum = newRailcar.vu20DocNum ? newRailcar.vu20DocNum : newRailcar.vu19DocNum
        newRailcar.master = newRailcar.master ? newRailcar.master : newRailcar.vu19Master
        newRailcar.vu20CreatedDate = item.vrd ? newRailcar.vu19CreatedDate : newRailcar.vu20CreatedDate
        newRailcar.vu20CreatedDate = newRailcar.vu20CreatedDate ? newRailcar.vu20CreatedDate.slice(0, 10) : ''
        newRailcar.hotProcessing = item.vrd ? 'гор' : newRailcar.hotProcessing
        return newRailcar
      } catch (e) {
        console.log(e)
      }
    },
    createExel () {
      let list = {}
      list = JSON.parse(JSON.stringify(this.data))
      const fileName = 'Реестр арендованных и  собственных вагон-цистерн ' + 'Все листы' + ' c ' +
          this.fromDate.slice(0, 10) + 'г.' + ' по ' + this.toDate.slice(0, 10)
      importExel(fileName, this.mainHeader, list, this.outcomeData, this.headerOutcome, [], ' c ' + this.fromDate + ' по ' + this.toDate)
    },
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    getFormattedDate (str) {
      return date.getFormattedDate(str)
    },
    parseToTime (str) {
      return date.parseToTime(str)
    }
  }
}
</script>

<style scoped>

</style>
