export default {
  data: [
    '',
    {
      id: 1,
      title: 'Ежелый М.Н.'
    },
    {
      id: 3,
      title: 'Кошкарбаев Е.Е.'
    },
    {
      id: 5,
      title: 'Сагындык Т.Т.'
    },
    {
      id: 7,
      title: 'Қапар Т.С.'
    },
    {
      id: 8,
      title: 'Бельгибаев А.А'
    },
    {
      id: 9,
      title: 'Кенесова Г.Е'
    },
    {
      id: 10,
      title: 'Сайлыбаева А.О'
    }
  ]
}
