<template>
  <div class="error-box">
    <el-result icon="error" title="Упс!" subTitle="Страница не найдена или находится в разработке">
      <template slot="extra">
        <el-button type="primary" @click="$router.push('/dashboard')">На главную</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.error-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

img {
  width: 250px;
}

h3 {
  color: #C0C4CC;
}

.el-button {
  margin-top: 20px;
  width: 250px;
}
</style>
