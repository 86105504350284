<template>
  <el-container id="app">
    <transition name="component-fade">
      <the-navigation v-if="$route.path !== '/login-page' && $route.path !== '/registration'"></the-navigation>
    </transition>
    <el-container>
      <el-main class="content">
        <transition name="component-fade" mode="out-in">
          <router-view/>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import TheNavigation from './components/layout/TheNavigation'

export default {
  components: {
    TheNavigation
  }
}
</script>

<style lang="scss">
  html, body, textarea {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: "Gotham", sans-serif !important;
    background: linear-gradient(90deg, #e6e9fa 0%, #f1f3f8 50%, #d6dcf5 80%);
  }

  #app {
    height: 100%;
  }

  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .2s ease;
  }
  .component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
</style>
