export default {
  data: [
    '',
    {
      id: 750,
      title: 'Вагон на ГО'
    },
    {
      id: 753,
      title: 'ТОР'
    },
    {
      id: 752,
      title: 'Тестовое действие'
    },
    {
      id: 750,
      title: 'Др/Кр'
    },
    {
      id: 751,
      title: 'Деповской ремонт'
    },
    {
      id: 753,
      title: 'Капитальный ремонт'
    },
    {
      id: 754,
      title: 'Вагон на ХО'
    },
    {
      id: 755,
      title: 'Вагон на ГО или ХО'
    }
  ]
}
