<template>
  <div>
    <h2 class="page-title">Документы</h2>
    <el-card>
      <el-upload
          class="upload-demo"
          ref="upload"
          :action="url"
          :file-list="fileList"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :limit="3"
          :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">Выбрать файл</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" :disabled="fileList.length === 0" @click="submitUpload">Проверить</el-button>
      </el-upload>
    </el-card>
    <el-card v-if="data.length > 0" style="margin-top: 30px;">
      <h3>Информация об электронном документе</h3>
      <table v-for="item in data" :key="item.certificate" style="margin-bottom: 30px;">
        <tr>
          <td>Результат проверки сертификата</td>
          <td>{{ item.result }}</td>
        </tr>
        <tr>
          <td>ИИН</td>
          <td>{{ item.iin }}</td>
        </tr>
        <tr>
          <td>Издатель</td>
          <td>{{ item.issuer_name }}</td>
        </tr>
        <tr>
          <td>ФИО</td>
          <td>{{ item.name + ' ' + item.father_name }}</td>
        </tr>
        <tr>
          <td>Серийный номер сертификата</td>
          <td>{{ item.certificate }}</td>
        </tr>
        <tr>
          <td>Срок действия сертификата</td>
          <td>{{ item.time_from + ' - ' + item.until }}</td>
        </tr>
        <tr v-if="item.organization">
          <td>Организация</td>
          <td>{{ item.organization }}</td>
        </tr>
        <tr v-if="item.organization_bin">
          <td>БИН организаций</td>
          <td>{{ item.organization_bin }}</td>
        </tr>
      </table>
    </el-card>
  </div>
</template>

<script>
import signApi from '@/modules/api/sign'
import { parse } from 'date-fns'

export default {
  name: 'CheckSign',
  data () {
    return {
      fileList: [],
      data: [
        // {
        //   issuer_name: 'ҰЛТТЫҚ КУӘЛАНДЫРУШЫ ОРТАЛЫҚ (RSA)',
        //   issuer_country: 'KZ',
        //   country: 'KZ',
        //   name: 'МАМЫРБАЕВ АЙДЫН',
        //   surname: 'МАМЫРБАЕВ',
        //   father_name: 'МУРАТОВИЧ',
        //   iin: '971006350032',
        //   email: 'CAPSLOCK722@GMAIL.COM',
        //   certificate: '61F7C5F7439E633F57104FFB4963AFEF89478B5C',
        //   organization: '',
        //   organization_bin: '',
        //   time_from: '19.09.2022 13:14:50 ALMT',
        //   until: '19.09.2023 13:14:50 ALMT'
        // }
      ]
    }
  },
  methods: {
    submitUpload (event) {
      const data = new FormData()
      data.append('file', this.fileList[0].raw, this.fileList[0].name)
      signApi.checkSign(data).then((resp) => {
        this.data = JSON.parse(resp.body).map(item => {
          const dateFormat = 'dd.MM.yyyy HH:mm:ss'
          item.time_from = item.time_from.split(' ').slice(0, -1).join(' ')
          item.until = item.until.split(' ').slice(0, -1).join(' ')
          item.time_from_date = parse(item.time_from, dateFormat, new Date())
          item.until_date = parse(item.until, dateFormat, new Date())
          item.result = item.until_date > new Date() ? 'Успешно' : 'Срок действия сертификата истек'
          return item
        })
        console.log(resp)
      }).catch(err => {
        console.log(err)
      })
      // this.$refs.upload.submit()
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
      this.fileList = []
    },
    handleChange (file, fileList) {
      console.log(file, fileList)
      this.fileList = [file]
    }
  }
}
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f3f3f3;
}
</style>
