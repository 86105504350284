<template>
  <div>
    <new-document-dialog
        :is-open="isNewDialog"
        @addNewService="addNewService"
        @close="closeDialogNew"
    />
    <h2 class="page-title">Документы</h2>
    <el-card>
      <el-table
          :data="filteredData"
          size="small"
          style="width: 100%">
        <el-table-column
            prop="id"
            label="Id"
        >
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Наименование"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="actions"
            fixed="right"
        >
          <template slot="header">
            <el-button size="medium" @click="isNewDialog = true" type="basic" icon="el-icon-plus"></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="editItem(scope.row)"
            >
              <i class="el-icon-edit"></i>
            </el-button>
                        <el-button
                            size="mini"
                            @click="deleteItem(scope.row)"
                            style="margin-left: 3px"
                        >
                          <i class="el-icon-delete"></i>
                        </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="Редактирование документа" :visible.sync="editDialogVisible" width="450px" :close-on-click-modal="false">
      <el-form size="small" label-position="top"  label-width="100px" :model="editForm" ref="ruleForm" :rules="editRules" >
        <el-form-item label="Наименование" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">Отмена</el-button>
    <el-button type="primary" @click="save">Сохранить</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import NewDocumentDialog from '../../components/dialogs/NewDocumentDialog'
import chainRequestsApi from '@/modules/api/chainRequests'

export default {
  name: 'SuperDocuments',
  components: {
    NewDocumentDialog
  },
  data () {
    return {
      isNewDialog: false,
      editDialogVisible: false,
      editForm: {
        id: '',
        name: ''
      },
      editRules: {
        name: [{ required: true, message: 'Введите наименование', trigger: 'blur' }]
      },
      search: '',
      data: []
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    filteredData () {
      return this.data
    }
  },
  methods: {
    getData () {
      chainRequestsApi.getDocuments().then(resp => {
        this.data = resp.data
      })
    },
    editItem (data) {
      this.editForm = {
        id: data.id,
        name: data.name
      }
      this.editDialogVisible = true
    },
    closeDialogNew (val) {
      this.isNewDialog = val
    },
    closeDialogEdit () {
      this.editDialogVisible = false
      this.editForm = {
        id: '',
        name: ''
      }
    },
    save () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const data = {
            name: this.editForm.name
          }
          chainRequestsApi.editDocument(this.editForm.id, data).then(resp => {
            console.log('resp', resp)
          }).then(() => {
            this.$notify({
              title: 'Изменение документа',
              message: 'Документ ' + this.editForm.name + ' успешно изменен.',
              type: 'success'
            })
          }).catch(err => {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Не удалось изменить документ.' + '\n' + err
            })
          }).finally(() => {
            this.getData()
            this.closeDialogEdit()
          })
        }
      })
    },
    addNewService (data) {
      console.log('data', data)
      chainRequestsApi.addNewDocument(data).then(resp => {
        console.log('comp resp', resp)
      }).then(() => {
        this.$notify({
          title: 'Добавление документа',
          message: 'Документ ' + data.name + ' успешно добавлен.',
          type: 'success'
        })
      }).catch(err => {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Не удалось добавить документ.' + '\n' + err
        })
      }).finally(() => {
        this.getData()
        this.closeDialogNew(false)
      })
    },
    deleteItem (data) {
      console.log('data', data)
      this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: 'Delete completed'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
