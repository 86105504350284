import Vue from 'vue'
import App from './App.vue'
import router from './router'
import directives from './directives'

import ElementUI from 'element-ui'
import './styles/element-variables.scss'
import './styles/components/index.scss'
import './styles/fonts.scss'

import 'element-ui/lib/theme-chalk/index.css'

import locale from 'element-ui/lib/locale/lang/ru-RU'

Vue.config.productionTip = false

// use ru locale
Vue.use(ElementUI, { locale })
// import directive
Vue.directive('click-outside', directives['click-outside'])

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
