import authorizationAPI from '@/modules/api/authorization'

const documentList = {
  BATYS: [
    { key: 'vu20', title: 'ВУ-20' },
    { key: 'vu20a', title: 'ВУ-20a' },
    { key: 'vu19', title: 'ВУ-19' },
    { key: 'vu22', title: 'ВУ-22' },
    { key: 'vu23', title: 'ВУ-23' },
    { key: 'vu36', title: 'ВУ-36' }
  ],
  ERTYS: [
    { key: 'vu20', title: 'ВУ-20' },
    { key: 'vu20a', title: 'ВУ-20a' },
    { key: 'vu19', title: 'ВУ-19' },
    { key: 'act', title: 'Акт' },
    { key: 'vu22', title: 'ВУ-22' },
    { key: 'vu23', title: 'ВУ-23' },
    { key: 'vu36', title: 'ВУ-36' },
    { key: 'statement', title: 'Ведомость' }
  ],
  KAZYKURT: [
    { key: 'vu20', title: 'ВУ-20' },
    { key: 'vu20a', title: 'ВУ-20a' },
    { key: 'vu19', title: 'ВУ-19' },
    { key: 'vu23', title: 'ВУ-23' },
    { key: 'vu22', title: 'ВУ-22' },
    { key: 'vu36', title: 'ВУ-36' },
    { key: 'du1Departure', title: 'ДУ-1 Убытие' },
    { key: 'du1MainNew', title: 'УВ (Прибытия)' }
  ]
}

const ticketList = {
  BATYS: [
    { key: 'processing', title: 'Заявка на обработку' },
    { key: 'raisingApplication', title: 'Заявка на поднятие состава' },
    { key: 'sendApplication', title: 'Заявка к отправке' },
    { key: 'uncreditedWagons', title: 'Уведомление на нераскредитованных вагонов' },
    { key: 'preparationApplication', title: 'Заявка на подготовку ТС' },
    { key: 'inspection', title: 'Заявка на Осмотр' },
    { key: 'tor', title: 'Заявка на ТОР' }
  ],
  ERTYS: [
    { key: 'processing', title: 'Заявка на обработку' },
    { key: 'vrd', title: 'Заявка на ВРД' }
  ],
  KAZYKURT: [
    { key: 'processing', title: 'Заявка на обработку' },
    { key: 'drKr', title: 'Заявка на ДрКР' },
    { key: 'externalCleaning', title: 'Заявка на наружную мойку' }
  ]
}

function getDocumentsList (station) {
  return documentList[station]
}

function getTicketsList (station) {
  return ticketList[station]
}

function getAllDocuments () {
  return documentList
}

function getAllTickets () {
  return ticketList
}
//
// function getErtysDocuments () {
//   return documentList.ERTYS
// }
//
// function getErtysTickets () {
//   return ticketList.ERTYS
// }
//
// function getKazykurtDocuments () {
//   return documentList.KAZYKURT
// }
//
// function getKazykurtTickets () {
//   return ticketList.KAZYKURT
// }

const permissions = {
  ROLE_ALL: {
    canWatchDocuments: true,
    canSignDocuments: true,
    canWatchTickets: true,
    canSignTickets: true,
    canCancelTickets: true,
    canCreateTickets: true,
    canWatchAgreement: true
  },
  ROLE_CREATE_TICKETS: {
    canWatchDocuments: true,
    canSignDocuments: false,
    canWatchTickets: true,
    canSignTickets: true,
    canCancelTickets: true,
    canCreateTickets: true,
    canWatchAgreement: false
  },
  ROLE_SIGN_DOCS: {
    canWatchDocuments: true,
    canSignDocuments: true,
    canWatchTickets: false,
    canSignTickets: false,
    canCancelTickets: false,
    canCreateTickets: false,
    canWatchAgreement: false
  },
  ROLE_VIEW_DOCS: {
    canWatchDocuments: true,
    canSignDocuments: false,
    canWatchTickets: false,
    canSignTickets: false,
    canCancelTickets: false,
    canCreateTickets: false,
    canWatchAgreement: false
  },
  ROLE_VIEW_TICKET: {
    canWatchDocuments: true,
    canSignDocuments: false,
    canWatchTickets: true,
    canSignTickets: false,
    canCancelTickets: false,
    canCreateTickets: false,
    canEditTickets: false,
    canWatchAgreement: false
  },
  ROLE_USER: {
    canWatchDocuments: true,
    canSignDocuments: true,
    canWatchTickets: true,
    canSignTickets: true,
    canCancelTickets: true,
    canCreateTickets: true,
    canWatchAgreement: true
  },
  ROLE_COMPANY_ADMIN: {
    canWatchDocuments: true,
    canSignDocuments: true,
    canWatchTickets: true,
    canSignTickets: true,
    canCancelTickets: true,
    canCreateTickets: true,
    canWatchAgreement: true
  },
  ROLE_AGREEMENT: {
    canWatchDocuments: false,
    canSignDocuments: false,
    canWatchTickets: false,
    canSignTickets: false,
    canCancelTickets: false,
    canCreateTickets: false,
    canWatchAgreement: true
  }
}

function canWatchDocuments (role) {
  if (role && permissions[role]) {
    return permissions[role].canWatchDocuments
  }
  return false
}

function canSignDocuments (role) {
  if (role && permissions[role]) {
    return permissions[role].canSignDocuments && !authorizationAPI.isRolePtc()
  }
  return false
}

function canWatchTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canWatchTickets
  }
  return false
}

function canSignTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canSignTickets && !authorizationAPI.isRolePtc()
  }
  return false
}

function canCancelTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canCancelTickets && !authorizationAPI.isRolePtc()
  }
  return false
}

function canCreateTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canCreateTickets && !authorizationAPI.isRolePtc()
  }
  return false
}

function canEditTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canEditTickets && !authorizationAPI.isRolePtc()
  }
  return false
}

function canWatchAgreement (role) {
  if (role && permissions[role]) {
    return permissions[role].canWatchAgreement
  }
  return false
}

export {
  canWatchAgreement,
  canWatchDocuments,
  canSignDocuments,
  canWatchTickets,
  canSignTickets,
  canCancelTickets,
  canCreateTickets,
  canEditTickets,
  getDocumentsList,
  getTicketsList,
  getAllDocuments,
  getAllTickets
}
