<template>
  <div>
    <new-service-dialog
        :is-open="isNewDialog"
        @addNewService="addNewService"
        @close="closeDialogNew"
    />
    <h2 class="page-title">Сервисы</h2>
    <el-card>
      <el-table
          :data="filteredData"
          size="small"
          style="width: 100%">
        <el-table-column
            prop="id"
            label="Id"
        >
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Наименование"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="ip"
            label="Ip"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.ip }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="bin"
            label="БИН"
        >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.bin }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="uuid"
            label="uuid"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.uuid }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="actions"
            fixed="right"
        >
          <template slot="header">
            <el-button size="medium" @click="isNewDialog = true" type="basic" icon="el-icon-plus"></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="editItem(scope.row)"
            >
              <i class="el-icon-edit"></i>
            </el-button>
<!--            <el-button-->
<!--                size="mini"-->
<!--                @click="editItem(scope)"-->
<!--                style="margin-left: 3px"-->
<!--            >-->
<!--              <i class="el-icon-delete"></i>-->
<!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="Редактирование сервиса" :visible.sync="editDialogVisible" width="450px" :close-on-click-modal="false">
      <el-form size="small" label-position="top"  label-width="100px" :model="editForm" ref="ruleForm" :rules="editRules" >
        <el-form-item label="Наименование" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="БИН" prop="bin">
          <el-input v-model="editForm.bin"></el-input>
        </el-form-item>
        <el-form-item label="IP" prop="ip">
          <el-input v-model="editForm.ip"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">Отмена</el-button>
    <el-button type="primary" @click="save">Сохранить</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import NewServiceDialog from '../../components/dialogs/NewServiceDialog'
import chainRequestsApi from '@/modules/api/chainRequests'

export default {
  name: 'SuperServices',
  components: {
    NewServiceDialog
  },
  data () {
    return {
      isNewDialog: false,
      editDialogVisible: false,
      editForm: {
        id: '',
        name: '',
        bin: '',
        ip: ''
      },
      editRules: {
        name: [{ required: true, message: 'Введите наименование', trigger: 'blur' }],
        bin: [{ required: true, message: 'Введите БИН', trigger: 'blur' }],
        ip: [{ required: true, message: 'Введите IP', trigger: 'blur' }]
      },
      search: '',
      data: []
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    filteredData () {
      return this.data
    }
  },
  methods: {
    getData () {
      chainRequestsApi.getServices().then(resp => {
        this.data = resp.data
      })
    },
    editItem (data) {
      this.editForm = {
        id: data.id,
        name: data.name,
        ip: data.ip,
        bin: data.bin
      }
      this.editDialogVisible = true
      console.log('data', data)
    },
    closeDialogNew (val) {
      this.isNewDialog = val
    },
    closeDialogEdit () {
      this.editDialogVisible = false
      this.editForm = {
        id: '',
        name: '',
        bin: '',
        ip: ''
      }
    },
    save () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          console.log('save', this.editForm)
          const data = {
            name: this.editForm.name,
            ip: this.editForm.ip,
            bin: this.editForm.bin
          }
          chainRequestsApi.editService(this.editForm.id, data).then(resp => {
            console.log('resp', resp)
          }).then(() => {
            this.$notify({
              title: 'Изменение сервиса',
              message: 'Сервис ' + this.editForm.name + ' успешно изменен.',
              type: 'success'
            })
          }).catch(err => {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Не удалось изменить cервис.' + '\n' + err
            })
          }).finally(() => {
            this.getData()
            this.closeDialogEdit()
          })
        }
      })
    },
    addNewService (data) {
      console.log('data', data)
      chainRequestsApi.addNewService(data).then(resp => {
        console.log('comp resp', resp)
      }).then(() => {
        this.$notify({
          title: 'Добавление сервиса',
          message: 'Сервис ' + data.name + ' успешно добавлен.',
          type: 'success'
        })
      }).catch(err => {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Не удалось добавить cервис.' + '\n' + err
        })
      }).finally(() => {
        this.getData()
        this.closeDialogNew(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
