<template>
  <div>
    <new-company-user-dialog
        :is-open="isNewUserDialog"
        :users="data"
        :companies="companies"
        :edit-item="editedItem"
        @addNewUser="addNewUser"
        @editUser="editUser"
        @close="closeDialogNew"
    />
    <h2 class="page-title">Пользователи</h2>
    <el-card>
      <el-table
          :data="filteredData"
          size="small"
          style="width: 100%">
        <el-table-column
            prop="name"
            label="Имя"
        >
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.userProfile.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="lastName"
            label="Фамилия"
        >
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.userProfile.lastName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="patronymic"
            label="Отчество"
        >
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.userProfile.patronymic }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="username"
            label="Логин"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.username }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="roleName"
            label="Роль"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.role }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="position"
            label="Позиция"
        >
          <template slot-scope="scope">
            <i class="el-icon-user-solid"></i>
            <span style="margin-left: 10px">{{ scope.row.userProfile.position }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="iin"
            label="ИИН"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.userProfile.iin }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="actions"
            fixed="right"
        >
          <template slot="header">
            <el-button size="medium" @click="openDialogNew" type="basic" icon="el-icon-plus"></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="editItem(scope.row)"
            >
              <i class="el-icon-edit"></i>
            </el-button>
<!--            <el-button-->
<!--                size="mini"-->
<!--                @click="editItem(scope)"-->
<!--                style="margin-left: 3px"-->
<!--            >-->
<!--              <i class="el-icon-delete"></i>-->
<!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import usersApi from '@/modules/api/users'
import companiesApi from '@/modules/api/companies'
import authorizationAPI, { getRole } from '@/modules/api/authorization'
import NewCompanyUserDialog from '@/components/dialogs/NewCompanyUserDialog'

export default {
  name: 'CompanyUsers',
  components: {
    NewCompanyUserDialog
  },
  data () {
    return {
      isNewUserDialog: false,
      editedItem: null,
      search: '',
      data: [],
      companies: []
    }
  },
  mounted () {
    this.getData()
    this.getCompanies()
  },
  computed: {
    filteredData () {
      return this.data
    },
    getCompanyId () {
      return authorizationAPI.getUsersCompanyId()
    }
  },
  methods: {
    getData () {
      usersApi.getCompanyUsers(this.getCompanyId).then(resp => {
        this.data = resp.data.map(item => {
          item.role = getRole(item.authorities)
          return item
        })
      })
    },
    getCompanies () {
      companiesApi.getCompanies().then(resp => {
        this.companies = resp
      })
    },
    openDialogNew () {
      this.editedItem = null
      this.isNewUserDialog = true
    },
    editItem (data) {
      console.log(data)
      this.editedItem = data
      this.isNewUserDialog = true
    },
    closeDialogNew (val) {
      this.isNewUserDialog = val
    },
    editUser (item) {
      const data = {
        name: item.name,
        lastName: item.lastName,
        patronymic: item.patronymic,
        position: item.position
      }
      usersApi.editUserInfo(item.id, data).then(() => {
        this.$notify({
          title: 'Редактирование пользователя',
          message: 'Пользователь ' + data.name + ' ' + data.lastName + ' успешно обновлен.',
          type: 'success'
        })
      }).catch(err => {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Не удалось обновить пользователя.' + '\n' + err
        })
      }).finally(() => {
        this.getData()
        this.closeDialogNew()
      })
    },
    addNewUser (data) {
      data.company = {
        id: this.getCompanyId
      }
      data.roleNames = ['ROLE_USER']
      data.roleNames.push(data.role)
      console.log('data', data)
      usersApi.addUserForCompany(data).then(resp => {
        console.log('comp resp', resp)
      }).then(() => {
        this.$notify({
          title: 'Добавление пользователя',
          message: 'Пользователь ' + data.name + ' ' + data.lastName + ' успешно добавлен.',
          type: 'success'
        })
      }).catch(err => {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Не удалось добавить пользователя.' + '\n' + err
        })
      }).finally(() => {
        this.getData()
        this.closeDialogNew()
      })
    }
  }
}
</script>

<style scoped>

</style>
