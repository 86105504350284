<template>
  <el-card v-loading="isLoading">
    <el-button @click="createExel">
      <i class="el-icon-download" style="font-weight: bold"/>
      Excel
    </el-button>
    <div class="text-center mb-4 mt-lg-n6 text--secondary font-weight-bold" style="text-align:center; width: 100%; font-size: 15px; margin: 30px 0; font-weight: bolder" v-html="reportSubtitle"></div>
    <el-tabs v-if="Object.keys(data).length > 1" v-model="tab" @tab-click="tabClick">
      <el-tab-pane
          v-for="(value, key) in data"
          :label="key"
          :name="key"
          :key="key">
        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <el-button @click="clearFilter()" size="mini">Сбросить все фильтра</el-button>
          <el-input
              v-model="search"
              @input="searchInput"
              style="width: 200px"
              placeholder="Поиск по вагонам">
            <el-button @click="searchData" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <el-table :data="value"
                  ref="filterTable"
                  height="450"
                  size="mini"
                  border>
          <el-table-column
              fixed
              type="index">
          </el-table-column>
          <el-table-column
              prop="vu20CreatedDate"
              label="Дата"
              :filters="globalFilter[key]['vu20CreatedDate']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="carNumber"
              label="Вагон"
              fixed
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="osn"
              label="Осные"
              :filters="globalFilter[key]['osn']"
              :filter-method="filterHandler"
              width="50">
          </el-table-column>
          <el-table-column
              prop="product"
              label="Из-под чего/Под что."
              :filters="globalFilter[key]['product']"
              :filter-method="filterHandler"
              width="180">
          </el-table-column>
          <el-table-column
              prop="operation"
              label="Вид опер."
              :filters="globalFilter[key]['operation']"
              :filter-method="filterHandler"
              width="120">
          </el-table-column>
          <el-table-column label="Вид обр.">
            <el-table-column
                prop="coldProcessing"
                label="Хол"
                :filters="globalFilter[key]['coldProcessing']"
                :filter-method="filterHandler"
                width="50">
            </el-table-column>
            <el-table-column
                prop="hotProcessing"
                label="Гор"
                :filters="globalFilter[key]['hotProcessing']"
                :filter-method="filterHandler"
                width="50">
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="torText"
              label="ТОР"
              :filters="globalFilter[key]['torText']"
              :filter-method="filterHandler"
              width="50">
          </el-table-column>
          <el-table-column
              prop="typeRepairVu23"
              label="Из-под чего  для Др,Кр"
              :filters="globalFilter[key]['typeRepairVu23']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="vrdText"
              label="для Др,Кр"
              :filters="globalFilter[key]['vrdText']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column label="Дата/Время ТОР">
            <el-table-column
                prop="startTorDate"
                label="Начало"
                :filters="globalFilter[key]['startTorDate']"
                :filter-method="filterHandler"
                width="100">
            </el-table-column>
            <el-table-column
                prop="endTorDate"
                label="Окончание"
                :filters="globalFilter[key]['endTorDate']"
                :filter-method="filterHandler"
                width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="vu20DocNum"
              label="№ акта"
              :filters="globalFilter[key]['vu20DocNum']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="master"
              label="Сменный мастер"
              :filters="globalFilter[key]['master']"
              :filter-method="filterHandler"
              width="120">
          </el-table-column>
          <el-table-column
              prop="du1MainCreatedDate"
              label="Прибытие"
              :filters="globalFilter[key]['du1MainCreatedDate']"
              :filter-method="filterHandler"
              width="130">
          </el-table-column>
          <el-table-column label="Дата год-сти п/налив">
            <el-table-column
                prop="startTimePodNaliv"
                label="Начало обр-ки"
                :filters="globalFilter[key]['startTimePodNaliv']"
                :filter-method="filterHandler"
                width="100">
            </el-table-column>
            <el-table-column
                prop="endTimePodNaliv"
                label="Оконч. обр-ки"
                :filters="globalFilter[key]['endTimePodNaliv']"
                :filter-method="filterHandler"
                width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="diffTimePodNaliv"
              label="Под обработкой"
              :filters="globalFilter[key]['diffTimePodNaliv']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="standardText"
              label="Время по тех.карте"
              :filters="globalFilter[key]['standardText']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="diffStandardTime"
              label="Разница"
              :filters="globalFilter[key]['diffStandardTime']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="dateDepartureText"
              label="Дата уборки с ППС"
              :filters="globalFilter[key]['dateDepartureText']"
              :filter-method="filterHandler"
              width="130">
          </el-table-column>
          <el-table-column
              prop="priceService"
              label="Расценка"
              :filters="globalFilter[key]['priceService']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="priceTOR"
              label="Ст-ть ТОР"
              :filters="globalFilter[key]['priceTOR']"
              :filter-method="filterHandler"
              width="80">
          </el-table-column>
          <el-table-column
              prop="priceVRD"
              label="Снятие знаков опасн."
              :filters="globalFilter[key]['priceVRD']"
              :filter-method="filterHandler"
              width="100">
          </el-table-column>
          <el-table-column
              prop="vu19CreatedDate"
              label="Дата ВУ-19"
              :filters="globalFilter[key]['vu19CreatedDate']"
              :filter-method="filterHandler"
              width="130">
          </el-table-column>
          <el-table-column
              prop="vu19DocNum"
              label="№ акта"
              :filters="globalFilter[key]['vu19DocNum']"
              :filter-method="filterHandler"
              width="50">
          </el-table-column>
          <el-table-column
              prop="vu19Master"
              label="Сменный мастер"
              :filters="globalFilter[key]['vu19Master']"
              :filter-method="filterHandler"
              width="120">
          </el-table-column>
          <el-table-column
              prop="senderCompany.title"
              label="Собственник"
              width="150">
          </el-table-column>
        </el-table>
        <p style="font-size: 12px; margin-top: 20px; text-align: right">
          Общее кол-во данных: {{ value.length }}
        </p>
      </el-tab-pane>
    </el-tabs>
    <el-table
        v-if="Object.keys(data).length > 1"
        :data="currentOutCome"
        size="mini"
    >
      <el-table-column
          prop="title"
          width="600"
          label="Наименование">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="Количество">
      </el-table-column>
      <el-table-column
          prop="price"
          label="Тариф">
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import date from '@/modules/directories/date/date'
import blockchainApi from '@/modules/api/blockchain'
import { format, parse } from 'date-fns'
import importExel from '@/modules/exel/register-common'

export default {
  name: 'RegisterDetail',
  data () {
    return {
      isLoading: false,
      fromDate: '',
      search: '',
      toDate: '',
      tab: '',
      reportSubtitle: '',
      documentInfo: {},
      data: {},
      dataAll: {},
      globalFilter: {},
      outComeData: {},
      currentOutCome: [],
      filterCarNumber: '',
      mainHeader: [
        { text: '№', value: 'index', width: 30, isNotFilter: true, rowspan: 2, colspan: 1 },
        { text: 'Дата', value: 'vu20CreatedDate', width: 100, rowspan: 2, colspan: 1 },
        { text: 'Вагон', value: 'carNumber', align: 'center', width: 80, isNotFilter: true, isLink: true, isSticky: true, rowspan: 2, colspan: 1 },
        { text: 'Осные', value: 'osn', align: 'center', width: 20, rowspan: 2, colspan: 1 },
        { text: 'Из-под чего/Под что.', value: 'product', align: 'center', width: 300, rowspan: 2, colspan: 1 },
        { text: 'Вид опер.', value: 'operation', align: 'operation', width: 150, rowspan: 2, colspan: 1 },
        {
          text: 'Вид обр.',
          value: 'processingTipeMain',
          align: 'center',
          width: 40,
          rowspan: 1,
          colspan: 2,
          subheader: [
            { text: 'Хол', value: 'coldProcessing', colspan: 1, rowspan: 1, align: 'center', width: 30 },
            { text: 'Гор', value: 'hotProcessing', colspan: 1, rowspan: 1, width: 30 }]
        },
        { text: 'ТОР', value: 'torText', align: 'center', width: 30, rowspan: 2, colspan: 1 },
        { text: 'Из-под чего  для Др,Кр', value: 'typeRepairVu23', align: 'center', width: 120, rowspan: 2, colspan: 1 },
        { text: 'для Др,Кр', value: 'vrdText', align: 'center', width: 120, rowspan: 2, colspan: 1 },
        {
          text: 'Дата/Время ТОР',
          value: 'torDate',
          align: 'center',
          width: 290,
          colspan: 2,
          rowspan: 1,
          subheader: [
            { text: 'Начало', value: 'startTorDate', colspan: 1, rowspan: 1, width: 145 },
            { text: 'Окончание', value: 'endTorDate', colspan: 1, rowspan: 1, width: 145 }]
        },
        { text: '№ акта', value: 'vu20DocNum', width: 150, isLink: true, rowspan: 2, colspan: 1 },
        { text: 'Сменный мастер', value: 'master', width: 250, rowspan: 2, colspan: 1 },
        { text: 'Прибытие', value: 'du1MainCreatedDate', width: 145, rowspan: 2, colspan: 1 },
        {
          text: 'Дата год-сти п/налив',
          value: 'timePodNaliv',
          width: 150,
          colspan: 2,
          rowspan: 1,
          subheader: [
            { text: 'Начало обр-ки', value: 'startTimePodNaliv', width: 75 },
            { text: 'Оконч. обр-ки', value: 'endTimePodNaliv', width: 75 }]
        },
        { text: 'Под обработкой', value: 'diffTimePodNaliv', width: 75, rowspan: 2, colspan: 1 },
        { text: 'Время по тех.карте', value: 'standardText', width: 75, rowspan: 2, colspan: 1 },
        { text: 'Разница', value: 'diffStandardTime', width: 75, rowspan: 2, colspan: 1 },
        { text: 'Дата уборки с ППС', value: 'dateDepartureText', width: 145, rowspan: 2, colspan: 1 },
        { text: 'Расценка', value: 'priceService', width: 80, rowspan: 2, colspan: 1 },
        { text: 'Ст-ть ТОР', value: 'priceTOR', width: 80, rowspan: 2, colspan: 1 },
        { text: 'Снятие знаков опасн.', value: 'priceVRD', align: 'center', width: 60, rowspan: 2, colspan: 1 },
        { text: 'Дата ВУ-19', value: 'vu19CreatedDate', width: 145, rowspan: 2, colspan: 1 },
        { text: '№ акта', value: 'vu19DocNum', width: 50, isLink: true, rowspan: 2, colspan: 1 },
        { text: 'Сменный мастер', value: 'vu19Master', width: 180, rowspan: 2, colspan: 1 },
        { text: 'Собственник', value: 'senderCompany', width: 200, isNotFilter: true, rowspan: 2, colspan: 1 }
      ],
      headerOutcome: [
        { text: 'Наименование', value: 'title' },
        { text: 'Количество', value: 'amount' },
        { text: 'Тариф', value: 'price' }
      ],
      dataInfo: {
        'Подготовка 4-осн в/ц горячим способом в целях их подготовки к текущему ремонту': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом в целях их подготовки под плановый вид ремонта': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом из под темных под темные нефтепродукты': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом из под темных под светлые нефтепродукты': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом из под светлых под светлые нефтепродукты': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц горячим способом из под нефтебитума под налив': { amount: 0, price: 0 },
        'Подготовка 4-осн в/ц холодным способом': { amount: 0, price: 0 },
        'ТОР 4 осных в/ц': { amount: 0, price: 0 },
        'Снятие знаков опасности с котла цистерны': { amount: 0, price: 0 },
        summary: { title: 'Итого сумма', price: 0, bold: true }
      }
    }
  },
  created () {
    this.getDocumentByHash()
    this.data['Реестр'] = []
    // this.createHeader()
  },
  mounted () {
  },
  computed: {
    filteredData () {
      return this.tableData.filter(row => {
        return row.carNumber.toLowerCase().includes(this.filterCarNumber.toLowerCase())
      })
    },
    getHash () {
      return this.$route.query.hash
    }
  },
  watch: {
  },
  methods: {
    searchInput () {
      if (!this.search) {
        this.data = JSON.parse(JSON.stringify(this.dataAll))
      }
    },
    searchData () {
      if (!this.search) {
        this.data = JSON.parse(JSON.stringify(this.dataAll))
      } else {
        for (const key in this.data) {
          this.data[key] = this.dataAll[key].filter(item => item.carNumber.includes(this.search))
        }
        this.data = JSON.parse(JSON.stringify(this.data))
      }
      console.log(this.data)
    },
    clearFilter () {
      this.$refs.filterTable.forEach(ref => {
        ref.clearFilter()
      })
    },
    createHeader () {
      this.mainHeader = this.mainHeader.map(head => {
        if (head.value === 'senderCompany') {
          head.input.band = this.sendersJournal
        }
        return head
      })
      this.subheader = []
      this.header = []
      this.mainHeader.forEach(head => {
        if (head.subheader) {
          this.subheader.push(...head.subheader)
          this.header.push(...head.subheader)
        } else {
          this.header.push(head)
        }
      })
    },
    filterHandler (value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    tabClick (tab, event) {
      this.currentOutCome = this.outComeData[tab.name]
    },
    getDocumentByHash () {
      this.isLoading = true
      const dataInfo = JSON.parse(JSON.stringify(this.dataInfo))
      blockchainApi.getDocumentByHash(this.getHash).then((resp) => {
        const dataSet = JSON.parse(resp.data.data)
        this.fromDate = date.getFormattedDate(dataSet.meta.fromDate)
        this.toDate = date.getFormattedDate(dataSet.meta.toDate)
        this.reportSubtitle = 'РЕЕСТР арендованных и  собственных вагон-цистерн компании,<br/>' +
            'проходящих осмотр котлов вагонов/цистерн и горячую обработку, а также ТОР<br/>' +
            'в ТОО "Ертыс-Сервис" c ' + this.fromDate + 'г.' + ' по ' + this.toDate
        this.documentInfo = resp.data
        const data = JSON.parse(resp.data.data).body
        const outComeData = {}
        outComeData['Реестр'] = []
        data.forEach((item) => {
          const key = item.owner ? item.owner.title : 'Без собственника'
          outComeData[key] = item.itog
            .filter(el => el.amount > 0)
            .map(el => {
              el.price = +el.price.toFixed(2)
              return el
            })
        })
        this.data['Реестр'] = []
        this.outComeData = outComeData
        data.forEach(item => {
          if (item.resp && item.resp.length > 0) {
            const key = item.owner ? item.owner.title : 'Без собственника'
            this.data[key] = []
            item.resp.map(railcar => {
              const newItem = this.createNewItem(railcar)
              newItem.senderCompany = newItem !== 'Без собственника' ? item.owner : ''
              newItem.owner = newItem !== 'Без собственника' ? item.owner : ''
              this.data[key].push(newItem)
              this.data['Реестр'].push(newItem)
            })
          }
        })
        for (const key in this.outComeData) {
          const sumPrice = { title: 'Итого сумма', price: 0, bold: true }
          this.outComeData[key].forEach(item => {
            if (dataInfo[item.title]) {
              dataInfo[item.title].title = item.title
              dataInfo[item.title].amount = this.rounded(item.amount + dataInfo[item.title].amount)
              dataInfo[item.title].price = this.rounded(item.price + dataInfo[item.title].price)
              dataInfo.summary.price = this.rounded(item.price + dataInfo.summary.price)
              sumPrice.price = this.rounded(item.price + sumPrice.price)
            }
          })
          this.outComeData[key].push(sumPrice)
        }
        this.outComeData['Реестр'] = Object.values(dataInfo).filter(item => item.title)
        this.currentOutCome = this.outComeData['Реестр']
        for (const key in this.data) {
          this.data[key] = this.sortRailcars(this.data[key])
        }
        this.tab = Object.keys(this.data)[0]
        this.dataAll = JSON.parse(JSON.stringify(this.data))
        this.updateFilters()
      }).finally(() => {
        this.isLoading = false
      })
    },
    rounded (number) {
      return +number.toFixed(2)
    },
    sortRailcars (railcars) {
      return railcars.sort((a, b) => {
        let value
        if (!b.vu20CreatedDate) {
          return -1
        } else if (!a.vu20CreatedDate) {
          return 1
        } else {
          const dateA = parse(a.vu20CreatedDate, 'dd.MM.yyyy', new Date())
          const dateB = parse(b.vu20CreatedDate, 'dd.MM.yyyy', new Date())
          value = dateA - dateB
        }
        if (value === 0) {
          const numberA = +(a.vu20DocNum.includes('/') ? a.vu20DocNum.split('/')[1] : a.vu20DocNum)
          const numberB = +(b.vu20DocNum.includes('/') ? b.vu20DocNum.split('/')[1] : b.vu20DocNum)
          if (numberA > numberB) {
            return 1
          } else if (numberA < numberB) {
            return -1
          }
          return a.railcarOnPPSId - b.railcarOnPPSId
        }
        return value
      })
    },
    updateFilters () {
      for (const key in this.data) {
        this.globalFilter[key] = {}
        this.mainHeader.forEach(head => {
          if (!head.isNotFilter) {
            const headKey = head.value
            const setters = {}
            setters[headKey] = new Set()
            this.data[key].forEach((item) => {
              setters[headKey].add(item[headKey])
            })
            this.globalFilter[key][headKey] = Array.from(setters[headKey]).map(i => ({ text: i, value: i }))
          }
          if (head.subheader) {
            head.subheader.forEach(sub => {
              const headKey = sub.value
              const setters = {}
              setters[headKey] = new Set()
              this.data[key].forEach((item) => {
                setters[headKey].add(item[headKey])
              })
              this.globalFilter[key][headKey] = Array.from(setters[headKey]).map(i => ({ text: i, value: i }))
            })
          }
        })
      }
    },
    createNewItem (item) {
      try {
        const newRailcar = item
        if (newRailcar.vu23 && newRailcar.vu23.typeRepairNewVu23 && newRailcar.vu23.typeRepairNewVu23.length > 0) {
          newRailcar.vu23.typeRepairNewVu23.forEach(i => {
            if (i.title.includes('ВРД') || i.title.includes('ВРЗ')) {
              if (newRailcar.vu23.outGoodVu23) {
                newRailcar.typeRepairVu23 = newRailcar.vu23.outGoodVu23.title
              }
            }
          })
        }
        newRailcar.carNumber = newRailcar.railcar.carNumber
        newRailcar.osn = '4-х'
        newRailcar.torText = item.tor ? 'ТОР' : ''
        newRailcar.vrdText = item.vrd ? 'для Др,КР' : ''
        newRailcar.dateDepartureText = this.getFormattedDate(item.dateDeparture)
        if (item.inGoodParent || item.outGoodParent) {
          newRailcar.operation = (item.outGoodParent ? item.outGoodParent.shortTitle : '-') + '/' + (item.inGoodParent ? item.inGoodParent.shortTitle : '-')
          if (newRailcar.operation === 'св/нб' || newRailcar.operation === 'тем/нб' || newRailcar.operation === 'св/тем' || newRailcar.operation === 'св/Битум') {
            newRailcar.operation = 'тем/тем'
          }
        }
        if (item.du1Main) {
          newRailcar.du1MainCreatedDate = this.getFormattedDate(item.du1Main.createdDate)
        }
        if (item.vu20) {
          newRailcar.vu20DocNum = item.vu20.docNum ? item.vu20.docNum : ''
          newRailcar.vu20CreatedDate = this.getFormattedDate(item.vu20.createdDate)
          newRailcar.product = (item.vu20.outGood ? item.vu20.outGood.title : '-') + '/' + (item.vu20.inGood ? item.vu20.inGood.title : '-')
          if (item.vu20.processingType && item.vu20.processingType.title) {
            newRailcar.coldProcessing = item.vu20.processingType.title.includes('Холодн') ? 'хол' : ''
            newRailcar.hotProcessing = item.vu20.processingType.title.includes('Горячая') ? 'гор' : ''
          }
          newRailcar.master = item.vu20.master ? item.vu20.master.map(x => x.title).join(', ') : ''
          if (item.vu20.startTime20) {
            newRailcar.startTimePodNaliv = format(new Date(item.vu20.startTime20), 'HH:mm:ss')
          }
          if (item.vu20.endTime20) {
            newRailcar.endTimePodNaliv = format(new Date(item.vu20.endTime20), 'HH:mm:ss')
          }
          if (item.vu20.startTime20 && item.vu20.endTime20) {
            let diffSecond = ((new Date(item.vu20.endTime20)).getTime() - (new Date(item.vu20.startTime20)).getTime())
            newRailcar.diffTimePodNaliv = this.parseToTime(diffSecond)
            if (item.timeStandardInSec) {
              diffSecond = (item.timeStandardInSec * 1000) - diffSecond
              newRailcar.diffStandardTime = this.parseToTime(diffSecond)
            }
          }
        }
        newRailcar.standardText = this.parseToTime(item.timeStandardInSec * 1000)
        if (item.vu19) {
          newRailcar.vu19DocNum = item.vu19.docNum ? item.vu19.docNum : ''
          newRailcar.vu19CreatedDate = this.getFormattedDate(item.vu19.createdDate)
          newRailcar.vu19Master = item.vu19.master ? item.vu19.master.map(x => x.title).join(', ') : ''
        }
        if (item.vu36Resp) {
          newRailcar.startTorDate = this.getFormattedDate(item.vu36Resp.vu36BeginDate)
          newRailcar.endTorDate = this.getFormattedDate(item.vu36Resp.vu36EndDate)
        }
        // если ву-20 отсутствует заполнить данными из ву-19
        newRailcar.vu20DocNum = newRailcar.vu20DocNum ? newRailcar.vu20DocNum : newRailcar.vu19DocNum
        newRailcar.master = newRailcar.master ? newRailcar.master : newRailcar.vu19Master
        newRailcar.vu20CreatedDate = item.vrd ? newRailcar.vu19CreatedDate : newRailcar.vu20CreatedDate
        newRailcar.vu20CreatedDate = newRailcar.vu20CreatedDate ? newRailcar.vu20CreatedDate.slice(0, 10) : ''
        newRailcar.hotProcessing = item.vrd ? 'гор' : newRailcar.hotProcessing
        return newRailcar
      } catch (e) {
        console.log(e)
      }
    },
    createExel () {
      const fileName = 'Реестр арендованных и  собственных вагон-цистерн ' + 'c ' + this.fromDate.slice(0, 10) + 'г.' + ' по ' + this.toDate.slice(0, 10)
      const newDate = JSON.parse(JSON.stringify(this.data))
      for (const key in newDate) {
        // eslint-disable-next-line no-prototype-builtins
        if (!newDate.hasOwnProperty(key)) {
          continue
        }
        newDate[key].forEach((x, i) => {
          x.index = i + 1
          x.senderCompany = x.senderCompany ? x.senderCompany.title : ''
        })
      }
      importExel(fileName, this.mainHeader, newDate, this.outComeData, this.headerOutcome, 'c ' + this.fromDate.slice(0, 10) + 'г.' + ' по ' + this.toDate.slice(0, 10))
    },
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    getFormattedDate (str) {
      return date.getFormattedDate(str)
    },
    parseToTime (str) {
      return date.parseToTime(str)
    }
  }
}
</script>

<style scoped>

</style>
