<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <h2 class="page-title">Договора</h2>
    </div>
    <el-card>
      <div style="margin-left: 70px; font-size: 14px; color: #5F6266; margin-bottom: 20px;">
        <span style="display: inline-block; width: 20vw">
          Контрагент
        </span>
        <span style="display: inline-block; width: 20vw">
          Номер и наим-ие договора
        </span>
        <span style="display: inline-block; width: 15vw">
          Дата регистрации
        </span>
        <span style="display: inline-block; width: 10vw">
          Дата создания
        </span>
      </div>
      <el-tree
          :props="props"
          :data="companies"
          :load="loadNode"
          @node-click="nodeClick"
          :render-content="renderTreeNode"
          lazy>
      </el-tree>
    </el-card>
  </div>
</template>

<script>
import blockchainApi from '@/modules/api/blockchain'
import authorizationAPI from '@/modules/api/authorization'
import date from '@/modules/directories/date/date'
import { canCreateTickets } from '@/modules/permissions/permissions'
import { startOfMonth } from 'date-fns'
import companiesApi from '@/modules/api/companies'

export default {
  name: 'CompanyAgreementPet',
  components: {
  },
  data () {
    const today = new Date()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth()
    const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1)
    const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1)
    const currentData = new Date()
    const fromDate = startOfMonth(currentData)
    const toDate = currentData
    return {
      props: {
        label: 'name',
        children: 'children'
      },
      isLoading: false,
      data: [],
      createDialogVisible: false,
      creationDocType: '',
      currentPage: 1,
      pageSize: 10,
      totalElements: 0,
      sortBy: '',
      orderBy: '',
      search: '',
      companies: [],
      notificantionCount: 0,
      pickerOptions: {
        shortcuts: [{
          text: 'Неделя',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Месяц',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 месяца',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultDateRange: [firstDayOfPreviousMonth, firstDayOfCurrentMonth],
      value1: '',
      dateRange: [
        fromDate,
        toDate
      ],
      monthsData: [
        { index: 1, label: 'Январь' },
        { index: 2, label: 'Февраль' },
        { index: 3, label: 'Март' },
        { index: 4, label: 'Апрель' },
        { index: 5, label: 'Май' },
        { index: 6, label: 'Июнь' },
        { index: 7, label: 'Июль' },
        { index: 8, label: 'Август' },
        { index: 9, label: 'Сентябрь' },
        { index: 10, label: 'Октябрь' },
        { index: 11, label: 'Ноябрь' },
        { index: 12, label: 'Декабрь' }
      ]
    }
  },
  created () {
  },
  mounted () {
    this.getCompanies()
  },
  computed: {
    getSigned () {
      if (this.$route.params.signed) {
        return this.$route.params.signed
      }
      return ''
    },
    getCompanyBin () {
      return authorizationAPI.getUsersCompanyBin()
    },
    canCreateTickets () {
      return canCreateTickets(authorizationAPI.getRole())
    },
    isRoleErtys () {
      return authorizationAPI.isRoleErtys()
    },
    isRoleKazykurt () {
      return authorizationAPI.isRoleKazykurt()
    },
    getUsersCompanyBin () {
      return authorizationAPI.getUsersCompanyBin()
    },
    filteredData () {
      return this.data
    },
    getDocumentTypes () {
      if (this.$route.params.types) {
        return this.$route.params.types.split(',')
      }
      return []
    }
  },
  watch: {
  },
  methods: {
    renderTreeNode (h, { node, data, store }) {
      if (node.level === 4) {
        return (
          <div style={{ fontSize: '14px' }}>
            <span style={{ display: 'inline-block', width: '20vw' }}>{ data.companyName }</span>
            <span style={{ display: 'inline-block', width: '20vw', color: '#8d4141' }}>{ data.agreementNumber + ' ' + data.agreementName }</span>
            <span style={{ display: 'inline-block', width: '15vw' }}>{ data.registryDate }</span>
            <span style={{ display: 'inline-block', width: '10vw' }}>{ data.created }</span>
          </div>
        )
      }
      return (
        <span>
          <span>{data.name}</span>
        </span>
      )
    },
    nodeClick (data, node) {
      if (node.level === 4) {
        this.$router.push({ path: '/company/agreement-detail', query: { hash: data.hash } })
      }
    },
    loadNode (node, resolve) {
      if (node.level === 0 || node.level === 4) {
        return resolve([])
      }
      if (node.level === 1) {
        blockchainApi.getAgreementPetroleum(node.data.bin).then(resp => {
          if (resp.length === 0) {
            return resolve([])
          }
          const data = resp.map(year => {
            year.name = year.year
            year.children = year.months.sort((a, b) => b.month - a.month).map(month => {
              this.monthsData.forEach(m => {
                if (m.index === month.month) {
                  month.name = m.label
                }
              })
              month.children = month.documents.map(item => {
                const dataInfo = JSON.parse(item.data)
                item.name = dataInfo.companyName + ' | ' + dataInfo.agreementName + ' ' + dataInfo.agreementNumber + ' | ' + date.getFormattedDate(dataInfo.createdDate)
                item.companyName = dataInfo.companyName
                item.agreementName = dataInfo.agreementName
                item.agreementNumber = dataInfo.agreementNumber
                item.agreementCreatedDate = date.getFormattedDate(dataInfo.createdDate)
                item.lastStatus = dataInfo.lastStatus
                item.price = dataInfo.price
                item.registryDate = date.getFormattedDate(dataInfo.registryDate)
                item.created = date.getFormattedDate(item.createdAt)
                return item
              })
              return month
            })
            return year
          })
          resolve(data)
        })
      } else {
        resolve(node.data.children)
      }
    },
    getCompanies () {
      companiesApi.getCompanies().then(resp => {
        this.companies = resp
      })
    },
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        console.log('err', e)
        return false
      }
      return true
    },
    openDocument (data) {
      this.$router.push({ path: '/company/agreement-detail', query: { hash: data.hash } })
    }
  }
}
</script>

<style>
.pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center
}
.el-table tr {
  cursor: pointer;
}
.el-table .error-row {
  background-color: #ffd8d8;
}
</style>
