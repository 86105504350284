<template>
  <el-dialog title="Добавить сервис" :visible.sync="dialogFormVisible" width="450px" :close-on-click-modal="false">
    <el-form size="small" label-position="top"  label-width="100px" :model="form" ref="ruleForm" :rules="rules" >
      <el-form-item label="Наименование" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">Отмена</el-button>
    <el-button type="primary" @click="save">Сохранить</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isOpen: Boolean
  },
  data () {
    return {
      dialogFormVisible: false,
      form: {
        name: ''
      },
      rules: {
        name: [{ required: true, message: 'Введите наименование', trigger: 'blur' }]
      }
    }
  },
  mounted () {
  },
  methods: {
    save () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$emit('addNewService', this.form)
          this.form = {
            name: ''
          }
        }
      })
    }
  },
  watch: {
    isOpen (val) {
      if (!val) {
        this.$refs['ruleForm'].resetFields()
      }
      this.dialogFormVisible = val
    },
    dialogFormVisible (val) {
      this.$emit('close', val)
    }
  }
}
</script>

<style scoped>
.el-dialog__body {
  padding: 10px 20px !important;
}
</style>
