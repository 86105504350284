import axios from 'axios'
import { MAIN_API } from './apiUrl'

const companiesApi = {
  findRailcars (carNumber) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/railcar/find?carNumber=' + carNumber,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  checkRailcar (carNumber) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/railcar/find/checking?carNumber=' + carNumber,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  addNewRailcar (carNumber) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/railcar?railcarNum=' + carNumber,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'POST',
        data: {}
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default companiesApi
