<template>
  <div>
    <new-user-dialog
        :is-open="isNewUserDialog"
        :users="data"
        :companies="companies"
        :edit-item="editedItem"
        @addNewUser="addNewUser"
        @editUser="editUser"
        @close="closeDialogNew"
    />
    <h2 class="page-title">Пользователи</h2>
    <el-card>
        <el-input
            v-model="search"
            @input="searchItem"
            style="margin-bottom: 20px; width: 20vw"
            placeholder="Поиск"/>
      <el-table
          :data="filteredData"
          size="small"
          style="width: 100%">
        <el-table-column
            prop="FIO"
            label="ФИО"
        >
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.userProfile.name + ' ' + scope.row.userProfile.lastName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="username"
            label="Логин"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.username }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="roleName"
            label="Роль"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.roleName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="position"
            label="Позиция"
        >
          <template slot-scope="scope">
            <i class="el-icon-user-solid"></i>
            <span style="margin-left: 10px">{{ scope.row.userProfile.position }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="iin"
            label="ИИН"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.userProfile.iin }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="companyName"
            label="Компания"
        >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.userProfile.company ? scope.row.userProfile.company.fullName : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="companyName"
            label="БИН"
        >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.userProfile.company ? scope.row.userProfile.company.bin : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="address"
            label="Адрес"
        >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.userProfile.company ? scope.row.userProfile.company.address : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="actions"
            fixed="right"
        >
          <template slot="header">
            <el-button size="medium" @click="openDialogNew" type="basic" icon="el-icon-plus"></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="editItem(scope.row)"
            >
              <i class="el-icon-edit"></i>
            </el-button>
<!--            <el-button-->
<!--                size="mini"-->
<!--                @click="editItem(scope)"-->
<!--                style="margin-left: 3px"-->
<!--            >-->
<!--              <i class="el-icon-delete"></i>-->
<!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import NewUserDialog from '../../components/dialogs/NewUserDialog'
import usersApi from '@/modules/api/users'
import companiesApi from '@/modules/api/companies'

export default {
  name: 'SuperUsers',
  components: {
    NewUserDialog
  },
  data () {
    return {
      isNewUserDialog: false,
      editedItem: null,
      search: '',
      data: [],
      filteredData: [],
      companies: []
    }
  },
  mounted () {
    this.getData()
    this.getCompanies()
  },
  computed: {
  },
  methods: {
    searchItem (event) {
      if (event) {
        this.filteredData = this.data.filter(item => item.fio.toLowerCase().includes(event.toLowerCase()) || item.username.toLowerCase().includes(event.toLowerCase()))
      } else {
        this.filteredData = JSON.parse(JSON.stringify(this.data))
      }
    },
    getData () {
      usersApi.getUsers().then(resp => {
        this.data = resp.data.map(item => {
          item.fio = item.userProfile.name + ' ' + item.userProfile.lastName
          if (item.authorities && item.authorities.length > 0) {
            item.roleName = item.authorities[0].name
          }
          return item
        })
        this.filteredData = JSON.parse(JSON.stringify(this.data))
      })
    },
    getCompanies () {
      companiesApi.getCompanies().then(resp => {
        this.companies = resp
      })
    },
    openDialogNew () {
      this.editedItem = null
      this.isNewUserDialog = true
    },
    editItem (data) {
      console.log(data)
      this.editedItem = data
      this.isNewUserDialog = true
    },
    closeDialogNew (val) {
      this.isNewUserDialog = val
    },
    editUser (item) {
      const data = {
        name: item.name,
        lastName: item.lastName,
        patronymic: item.patronymic,
        position: item.position
      }
      usersApi.editUserInfo(item.id, data).then(() => {
        this.$notify({
          title: 'Редактирование пользователя',
          message: 'Пользователь ' + data.name + ' ' + data.lastName + ' успешно обновлен.',
          type: 'success'
        })
      }).catch(err => {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Не удалось обновить пользователя.' + '\n' + err
        })
      }).finally(() => {
        this.getData()
        this.closeDialogNew()
      })
    },
    addNewUser (data) {
      data.company = {
        id: data.company
      }
      data.roleNames = ['ROLE_USER']
      data.roleNames.push(data.role)
      usersApi.addUserForCompany(data).then(resp => {
        console.log('comp resp', resp)
      }).then(() => {
        this.$notify({
          title: 'Добавление пользователя',
          message: 'Пользователь ' + data.name + ' ' + data.lastName + ' успешно добавлен.',
          type: 'success'
        })
      }).catch(err => {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Не удалось добавить пользователя.' + '\n' + err
        })
      }).finally(() => {
        this.getData()
        this.closeDialogNew()
      })
    }
  }
}
</script>

<style scoped>

</style>
