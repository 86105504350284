import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '@/views/Main.vue'

import Error from '../views/Error'
import Users from '@/views/super/Users'
import Services from '@/views/super/Services'
import Documents from '@/views/super/Documents'
import authorizationAPI from '@/modules/api/authorization'

import CompanyDocuments from '@/views/company/Documents'
import CompanyAgreement from '@/views/company/Agreement'
import CompanyAgreementPet from '@/views/company/AgreementPetroleum'
import CompanyRegister from '@/views/company/register/Register'
import CompanyUsers from '@/views/company/Users'
import CreateVu20 from '@/views/company/documents/CreateVu20'
import CheckSign from '@/views/company/CheckSign'
import CreateVu19 from '@/views/company/documents/CreateVu19'
import CreateTicket from '@/views/company/tickets/CreateTicket'
import AgreementDetail from '@/views/company/AgreementDetail'
import CreateAct from '@/views/company/documents/CreateAct'
import RegisterDetail from '@/views/company/register/RegisterDetail'
import RegisterKazykurt from '@/views/company/register/RegisterKazykurt'
import RegisterBatys from '@/views/company/register/RegisterBatys'
import DocumentsDetail from '@/views/company/documents/DocumentsDetail'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: Error
  },
  {
    path: '/',
    component: Main
  },
  {
    path: '/dashboard',
    component: Main
  },
  {
    path: '/login-page',
    component: () => import('../views/Authorization')
  },
  {
    path: '/registration',
    component: () => import('../views/Registration')
  },
  {
    path: '/super/users',
    component: Users
  },
  {
    path: '/super/services',
    component: Services
  },
  {
    path: '/super/documents',
    component: Documents
  },
  {
    path: '/company/users',
    component: CompanyUsers
  },
  {
    path: '/company/documents',
    component: CompanyDocuments
  },
  {
    path: '/company/documents/:types',
    component: CompanyDocuments
  },
  {
    path: '/company/register',
    component: CompanyRegister
  },
  {
    path: '/company/register-detail',
    component: RegisterDetail
  },
  {
    path: '/company/register-kaz',
    component: RegisterKazykurt
  },
  {
    path: '/company/register-batys',
    component: RegisterBatys
  },
  {
    path: '/company/agreement',
    component: CompanyDocuments
  },
  {
    path: '/company/agreement/:types/:signed',
    component: CompanyAgreement
  },
  {
    path: '/company/agreementpet/:types',
    component: CompanyAgreementPet
  },
  {
    path: '/company/agreement-detail',
    component: AgreementDetail
  },
  {
    path: '/company/create-vu20',
    component: CreateVu20
  },
  {
    path: '/company/checkSign',
    component: CheckSign
  },
  {
    path: '/company/create-ticket',
    component: CreateTicket
  },
  {
    path: '/company/create-ticket/:name',
    component: CreateTicket
  },
  {
    path: '/company/documents-detail',
    component: DocumentsDetail
  },
  {
    path: '/company/documents-detail/:type',
    component: DocumentsDetail
  },
  {
    path: '/company/create-vu20',
    component: CreateVu20
  },
  {
    path: '/company/create-vu20/:id',
    component: CreateVu20
  },
  {
    path: '/company/create-vu19',
    component: CreateVu19
  },
  {
    path: '/company/create-vu19/:id',
    component: CreateVu19
  },
  {
    path: '/company/create-act',
    component: CreateAct
  },
  {
    path: '/company/create-act/:id',
    component: CreateAct
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ((to.path !== '/login-page' && to.path !== '/registration') && !authorizationAPI.isAuthenticated()) {
    next('/login-page')
  }
  if (to.path === '/super/users' || to.path === '/super/services' || to.path === '/super/documents') {
    if (authorizationAPI.isRoleSuperAdmin()) next()
  } else next()
})

// localStorage.setItem('isLogined', false)

// router.beforeEach((to, from, next) => {
//   console.log('to', to)
//   if (localStorage.getItem('token')) {
//     next()
//   } else {
//     next('/login-page')
//   }
// })

export default router
