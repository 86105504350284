export default {
  data: [
    '',
    {
      id: 780,
      title: 'Керосин',
      shortCodeName: 'ТС',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 779,
      title: 'Бензин',
      shortCodeName: 'БН',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 778,
      title: 'Диз/топливо',
      shortCodeName: 'ДТ',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 776,
      title: 'Мазут',
      shortCodeName: 'МТ',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'mazut',
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 781,
      title: 'Битум',
      shortCodeName: 'БИТ',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 3,
        title: 'Нефтебитум',
        shortTitle: 'нефтебитум',
        key: 'neftehim'
      }
    },
    {
      id: 1095,
      title: 'Брак возврат',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'predVu23',
      binForContractor: null,
      email: '',
      receiveMailing: false,
      orderliness: null,
      productParentType: null
    },
    {
      id: 783,
      title: 'В/газойль',
      shortCodeName: 'ВГО',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 1100,
      title: 'Ву-19',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'predVu19',
      binForContractor: null,
      email: '',
      receiveMailing: false,
      orderliness: null,
      productParentType: null
    },
    {
      id: 988,
      title: 'Мазут РЖД',
      shortCodeName: 'МТ РЖД',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'mazut',
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 989,
      title: 'Мазут САЗ',
      shortCodeName: 'МТ САЗ',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'mazut',
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 782,
      title: 'Масло ',
      shortCodeName: 'масло ',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 1094,
      title: 'Нет заявки',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '',
      receiveMailing: false,
      orderliness: null,
      productParentType: null
    },
    {
      id: 777,
      title: 'Нефть',
      shortCodeName: 'НФ',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 936,
      title: 'Пищевой',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: null
    },
    {
      id: 881,
      title: 'По факту',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'poFactu',
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: null
    },
    {
      id: 985,
      title: 'Светлый',
      shortCodeName: 'СВ',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 986,
      title: 'Темный',
      shortCodeName: 'ТМ',
      shortTitle: null,
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T09:40:24.916+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      orderliness: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    }
  ]
}
