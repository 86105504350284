import { Workbook } from 'exceljs'
import { createCell, createDefaultTable, importExel } from '@/modules/exel/common'

let worksheet = null
let row = 1

function createHeader (title, header) {
  const maxRowLength = header.reduce((acc, item) => {
    acc += item.colspan
    return acc
  }, 0)
  title.forEach(x => {
    createCell(row, 1, x, worksheet, { isTableCell: false, isBold: true })
    worksheet.mergeCells(row, 1, row, maxRowLength)
    row++
  })
  row++
}

function createOutcomeData (title, outcomeData, headerOutcome) {
  row += 2
  const cell = worksheet.getCell(row, 1)
  cell.value = title[title.length - 2]
  worksheet.mergeCells(row, 1, row, 12)
  row += 2

  outcomeData.forEach((x, i) => {
    headerOutcome.forEach((head, y) => {
      let column = 12 + y
      if (y === 0) {
        column = 1
      } else if (y === 2) {
        column = 12 + 1 + y
      }
      const cell = worksheet.getCell(row, column)
      cell.value = x[head.value]
      if (i === 0 || i === outcomeData.length - 1) {
        cell.font = { bold: true }
      }
      if (column === 1) {
        worksheet.mergeCells(row, column, row, 12)
      }
    })
    row++
  })
}

function addSignatories (signatories) {
  signatories.forEach((x) => {
    worksheet.getCell(row, 1).value = x.name
    worksheet.getCell(row, 5).border = { bottom: { style: 'thin' } }
    worksheet.getCell(row, 6).value = x.value
    row += 2
  })
}

export default function (title, tableHeader, table, outcomeData, headerOutcome, signatories, dates) {
  row = 1
  const workbook = new Workbook()
  for (const key in table) {
    // eslint-disable-next-line no-prototype-builtins
    if (!table.hasOwnProperty(key)) {
      continue
    }
    let company = ''
    if (key === 'Общий реестр') {
      company = 'всех компаний'
    } else if (key === 'без собственника') {
      company = 'без собственника'
    } else {
      company = 'компании ' + key
    }
    const subtitle = [
      'РЕЕСТР арендованных и  собственных вагон-цистерн ' + company,
      'проходящих осмотр котлов вагонов/цистерн и горячую обработку, а также ТОР',
      'в ТОО "Казыкурт-Юг" ' + dates,
      ' '
    ]
    worksheet = workbook.addWorksheet(key, {
      properties: { defaultColWidth: 20, defaultRowHeight: 20 },
      pageSetup: { orientation: 'landscape', fitToHeight: 1000000, fitToWidth: 1 }
    })
    createHeader(subtitle, tableHeader)
    row = createDefaultTable(tableHeader, table[key], worksheet, row)
    createOutcomeData(subtitle, outcomeData[key], headerOutcome)
    row += 2
    addSignatories(signatories)
    row = 1
  }
  importExel(workbook, title)
}
