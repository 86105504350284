import axios from 'axios'
import { MAIN_API } from './apiUrl'

const companiesApi = {
  getCompanies () {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/company',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default companiesApi
