import { Workbook } from 'exceljs'
import { createCell, createDefaultTable, importExel } from '@/modules/exel/common'

let worksheet = null
let row = 1

function createHeader (title, header) {
  const maxRowLength = header.reduce((acc, item) => {
    acc += item.colspan
    return acc
  }, 0)
  title.forEach(x => {
    createCell(row, 1, x, worksheet, { isTableCell: false, isBold: true })
    worksheet.mergeCells(row, 1, row, maxRowLength)
    row++
  })
}

function createOutcomeData (outcomeData, headerOutcome) {
  row += 2

  headerOutcome.forEach((head, y) => {
    let column = 12 + y
    if (y === 0) {
      column = 1
    } else if (y === 2) {
      column = 12 + y
    }
    createCell(row, column, head.text, worksheet, {
      isTableCell: true,
      isBold: true,
      direction: 0
    })
    if (column === 1) {
      worksheet.mergeCells(row, column, row, 12)
    }
  })
  row += 1

  outcomeData.forEach((x, i) => {
    headerOutcome.forEach((head, y) => {
      let column = 12 + y
      if (y === 0) {
        column = 1
      } else if (y === 2) {
        column = 12 + y
      }
      createCell(row, column, x[head.value], worksheet, {
        isTableCell: true,
        isBold: i === outcomeData.length - 1
      })
      if (column === 1) {
        worksheet.mergeCells(row, column, row, 12)
      }
    })
    row++
  })
}

export default function (title, tableHeader, table, outComeData, headerOutcome, dates) {
  row = 1
  // eslint-disable-next-line
  let workbook = new Workbook()
  for (const key in table) {
    // eslint-disable-next-line no-prototype-builtins
    if (!table.hasOwnProperty(key)) {
      continue
    }
    let company = ''
    if (key === 'Без собственника') {
      company = 'без собственника'
    } else if (key !== 'Реестр') {
      company = 'компании ' + key
    }
    const subtitle = [
      'РЕЕСТР арендованных и  собственных вагон-цистерн ' + company,
      'проходящих осмотр котлов вагонов/цистерн и горячую обработку, а также ТОР',
      'в ТОО "Ертыс-Сервис" ' + dates,
      ' '
    ]
    worksheet = workbook.addWorksheet(key, {
      properties: { defaultColWidth: 20, defaultRowHeight: 20 },
      pageSetup: { orientation: 'landscape', fitToHeight: 1000000, fitToWidth: 1 }
    })
    createHeader(subtitle, tableHeader)
    row = createDefaultTable(tableHeader, table[key], worksheet, row)
    createOutcomeData(outComeData[key], headerOutcome)
    row = 1
  }
  importExel(workbook, title)
}
