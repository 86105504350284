import axios from 'axios'
import { MAIN_API } from './apiUrl'
// import {reject, resolve} from "eslint-plugin-promise/rules/lib/promise-statics";

const usersApi = {
  getUsers () {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/user',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'GET'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getCompanyUsers (id) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/user/all/by/company?companyId=' + id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'GET'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  addUserForCompany (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/user/add/for/company',
        data: data,
        headers: { 'Content-Type': 'application/json' },
        method: 'POST'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  editUserInfo (id, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/user/profile',
        data: data,
        params: { id },
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default usersApi
