export default {
  data: [
    '',
    {
      id: 602,
      title: 'АНПЗ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'ANPZ',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 664,
      title: 'Аутн',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'AUTN',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 593,
      title: 'К отправлению',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'departure',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 601,
      title: 'На АНПЗ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'toANPZ',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 595,
      title: 'На АУТН',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'toAUTN',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 599,
      title: 'На ППС',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'toPPS',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 592,
      title: 'На ПХСНГ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'toPHSNG',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 597,
      title: 'На ТОР',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'toTOR',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 603,
      title: 'ППС',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'PPS',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 661,
      title: 'ПХСНГ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'PHSNG',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 600,
      title: 'С АНПЗ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'fromANPZ',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 594,
      title: 'С АУТН',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'fromAUTN',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 598,
      title: 'С ППС',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'fromPPS',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 1493,
      title: 'С ПУТИ РТИ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'fromPathRti',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 591,
      title: 'С ПХСНГ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'fromPHSNG',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 596,
      title: 'С ТОР',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'fromTOR',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 665,
      title: 'ТОР',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-04-30T07:06:59.381+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2023-09-28T09:26:23.747+0500',
        deletedAt: null,
        id: 7,
        title: 'Направления',
        isDeletable: false,
        key: null
      },
      key: 'TOR',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    }
  ]
}
