import axios from 'axios'
import { MAIN_API } from './apiUrl'

const blockchainApi = {
  getDocuments () {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/blockchain',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'GET'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getDocumentsPaginated (fromDate, toDate, page, perPage, docTypes = ['vu20'], sortBy = '', orderBy = '', bin, search) {
    docTypes = docTypes.join(',')
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/blockchain/paginated',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        params: { fromDate, toDate, page, perPage, sortBy, orderBy, docTypes, bin, searchValue: search, searchKey: search ? 'docNumber' : '', withFiles: docTypes.includes('agreement') ? 1 : 0 },
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getAgreement (fromDate, toDate, bin, perPage, page, signed = false) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/agreements/paginated',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        params: { fromDate, toDate, bin, perPage, page, signed },
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getAgreementPetroleum (bin) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/agreements/signed',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        params: { bin },
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createDocument (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/blockchain',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        data,
        method: 'POST'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  setStatus (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/block/statuses/create',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        data,
        method: 'POST'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getStatus (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/block/statuses',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        data,
        method: 'POST'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  filterDocument (hash) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/blockchain/filter',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        data: { hash },
        method: 'POST'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getDocumentByHash (hash) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/blockchain/block',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        data: { hash },
        method: 'POST'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getNotificationsCount (bin) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/blockchain/count',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        params: {
          bin
        },
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  addDocumentSigners (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/document/signers/add',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        data,
        method: 'POST'
      }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getDocumentSigners (documentId) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/document/signers/get',
        params: {
          documentId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default blockchainApi
