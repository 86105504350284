
// const currentHostUrl = window.location.protocol + '//' + window.location.hostname

// Development
// const currentHostUrl = 'http://10.3.10.241'
// const currentHostUrl = 'http://185.4.180.72'
const currentHostUrl = 'https://portal.petroleum.com.kz'
// console.log(window.location)

const JASPER_API = 'http://89.219.13.218:8082'
const MAIN_API = currentHostUrl + ':9090'
const SIGN_API = currentHostUrl + ':8000'

export { MAIN_API, SIGN_API, JASPER_API }
