export default {
  data: [
    '',
    {
      id: 753,
      title: 'ВРД'
    },
    {
      id: 750,
      title: 'Вагон на ГО'
    },
    {
      id: 751,
      title: 'Вагон на ХО'
    },
    {
      id: 752,
      title: 'ТОР'
    }
  ]
}
