import { saveAs } from 'file-saver'

export const createCell = function (x, y, value, worksheet, { isTableCell, isBold, alignment, direction = 0 }) {
  // eslint-disable-next-line
  let cell = worksheet.getCell(x, y)

  cell.value = value
  cell.border = isTableCell ? { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } } : {}
  cell.font = isBold ? { bold: true } : {}

  const alignmentHorizontal = alignment || 'center'
  cell.alignment = { vertical: 'middle', horizontal: alignmentHorizontal, wrapText: true, textRotation: direction || 0 }

  return cell
}

export const createDefaultTable = function (tableHeader, tableBody, worksheet, row) {
  worksheet.getRow(row).height = 35
  // eslint-disable-next-line
  let header = []
  let column = 1
  let rowTable = row
  // Создать заголовок таблицы
  tableHeader.forEach(head => {
    createCell(row, column, head.text, worksheet, {
      isTableCell: true,
      isBold: true,
      direction: head.verticalHead ? 90 : 0
    })

    if (head.colspan > 1 || head.rowspan > 1) {
      worksheet.mergeCells(row, column, row + head.rowspan - 1, column + head.colspan - 1)
    }

    if (head.subheader) {
      rowTable = rowTable === row ? rowTable + 1 : rowTable
      worksheet.getRow(row + 1).height = 60

      head.subheader.forEach((item, i) => {
        header.push(item)
        const cell = createCell(row + 1, column + i, item.text, worksheet, {
          isTableCell: true,
          isBold: true,
          direction: item.verticalHead ? 90 : 0
        })
        if (item.verticalHead) {
          cell.width = 0
          worksheet.getRow(row + 1).height = 200
        }
      })
    } else {
      header.push(head)
    }
    column += (head.colspan ? head.colspan : 1)
  })

  header.forEach((head, y) => {
    let maxWidth = 12
    tableBody.forEach((item, i) => {
      let value = item[head.value]
      if (value && value.title) {
        value = value.title
      }
      createCell(rowTable + 1 + i, y + 1, value, worksheet, { isTableCell: true, isBold: item.index === 'Итого' })
      maxWidth = value && value.toString().length >= maxWidth ? value.length + 1 : maxWidth
    })

    worksheet.getColumn(1 + y).width = maxWidth
  })
  return rowTable + tableBody.length
}

export const importExel = function (workbook, title) {
  workbook.xlsx.writeBuffer().then((data) => {
    // eslint-disable-next-line
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, title + '.xlsx')
  })
}
