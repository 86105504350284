import { format } from 'date-fns'

const date = {
  getFormattedDate (str) {
    const x = new Date()
    let date = ''
    if (str) {
      try {
        const attributeValueDate = new Date(str)
        const utc = attributeValueDate.getTime() + (x.getTimezoneOffset() * 60000)
        const nd = new Date(utc + (3600000 * 5))
        date = format(nd, 'dd.MM.yyyy HH:mm')
      } catch (e) {
        date = str
      }
    }
    return date
  },
  parseToTime (milliseconds) {
    let result = milliseconds < 0 ? '-' : ''
    const posValue = Math.abs(milliseconds / 1000)

    const hours = Math.floor(posValue / 3600)
    const minutes = Math.floor((posValue - hours * 3600) / 60)
    const seconds = Math.floor(posValue - hours * 3600 - minutes * 60)

    result += hours + ':'
    result += (minutes.toString().length > 1 ? minutes : '0' + minutes) + ':'
    result += (seconds.toString().length > 1 ? seconds : '0' + seconds)
    return result
  }
}

export default date
