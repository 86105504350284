export default {
  data: [
    '',
    {
      id: 759,
      title: 'Бензин',
      shortCodeName: null,
      shortTitle: 'бн',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '1',
      receiveMailing: false,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 760,
      title: 'диз топливо',
      shortCodeName: null,
      shortTitle: 'дт',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '2',
      receiveMailing: false,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 761,
      title: 'Керосин',
      shortCodeName: null,
      shortTitle: 'ТС',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '3',
      receiveMailing: false,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 764,
      title: 'Мазут',
      shortCodeName: null,
      shortTitle: 'МТ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '4',
      receiveMailing: false,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 891,
      title: 'Нефтебитум',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '7',
      receiveMailing: false,
      productParentType: {
        id: 3,
        title: 'Нефтехимия',
        shortTitle: 'нб',
        key: 'neftehim'
      }
    },
    {
      id: 768,
      title: 'Светлый ',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '9',
      receiveMailing: false,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 769,
      title: 'темный',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '10',
      receiveMailing: false,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 762,
      title: 'Параксилол',
      shortCodeName: null,
      shortTitle: 'Параксилол',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '11',
      receiveMailing: false,
      productParentType: {
        id: 4,
        title: 'Параксилол',
        shortTitle: 'бензол-параксилол',
        key: 'paraks'
      }
    },
    {
      id: 943,
      title: 'Б/Т',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '46',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 763,
      title: 'Бензол',
      shortCodeName: null,
      shortTitle: 'Бензол',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '12',
      receiveMailing: false,
      productParentType: {
        id: 4,
        title: 'Параксилол',
        shortTitle: 'бензол-параксилол',
        key: 'paraks'
      }
    },
    {
      id: 940,
      title: 'ВРД',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '38',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 948,
      title: 'ВРД без обработки',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '42',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 946,
      title: 'ВРД с анализом',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '40',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 941,
      title: 'ВРЗ',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '41',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 949,
      title: 'ВРЗ без обработки',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '39',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 947,
      title: 'ВРЗ с анализом',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '43',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 771,
      title: 'Газ.конденсат',
      shortCodeName: null,
      shortTitle: 'газ.конд',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '13',
      receiveMailing: false,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 966,
      title: 'Груз неизв.',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '',
      receiveMailing: false,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 954,
      title: 'Мазут КЗХ',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '5',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 772,
      title: 'Масло',
      shortCodeName: null,
      shortTitle: 'масло',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '17',
      receiveMailing: false,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 765,
      title: 'Нефть',
      shortCodeName: null,
      shortTitle: 'НФ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '15',
      receiveMailing: false,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 942,
      title: 'ППС',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '44',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 773,
      title: 'Печное топливо',
      shortCodeName: null,
      shortTitle: 'ПТ',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '8',
      receiveMailing: false,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 767,
      title: 'Сажа',
      shortCodeName: null,
      shortTitle: 'Сажа',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '16',
      receiveMailing: false,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 944,
      title: 'б/обработки',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '45',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 921,
      title: 'барашек',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '19',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 922,
      title: 'в.клап',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '25',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 930,
      title: 'в.лестница',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '21',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 923,
      title: 'валик',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '31',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 924,
      title: 'винт',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '27',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 925,
      title: 'втулка',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '26',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 770,
      title: 'газойль',
      shortCodeName: null,
      shortTitle: 'газойль',
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '14',
      receiveMailing: false,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 926,
      title: 'заг-ка',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '22',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 927,
      title: 'к.гайка',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '32',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 929,
      title: 'клапан',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '18',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 952,
      title: 'люк',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '36',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 953,
      title: 'мазут СНГ',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '6',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 931,
      title: 'н.лестница',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '24',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 932,
      title: 'площадка',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '20',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 933,
      title: 'ригель',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '23',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 951,
      title: 'рычаг',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '33',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 934,
      title: 'скоба',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '34',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 935,
      title: 'стойка',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '28',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 936,
      title: 'тип',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '37',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 937,
      title: 'ухо',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '35',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 939,
      title: 'шт-ль',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '29',
      receiveMailing: false,
      productParentType: null
    },
    {
      id: 938,
      title: 'штанга',
      shortCodeName: null,
      shortTitle: null,
      band: {
        version: 3,
        createdBy: 'demo',
        createdAt: '2020-10-01T09:27:25.843+0000',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2021-03-10T09:33:41.105+0000',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      binForContractor: null,
      email: '30',
      receiveMailing: false,
      productParentType: null
    }
  ]
}
